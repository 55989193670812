import {Navigate, Routes, Route, Outlet} from 'react-router-dom';
import {PageLink, PageTitle} from './../../../_metronic/layout/core'
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {KTSVG, toAbsoluteUrl} from './../../../_metronic/helpers'
import * as API from './../../api';
import {Modal} from 'react-bootstrap';
import UserModal from './UserModal';
import MessageModal from './../utils/MessageModal';
import DeleteUserModal from './DeleteUserModal';
import { getUserData, isSuperAdmin } from '../../modules/auth';
import BtnTrash from "../../components/BtnTrash";
import BtnEdit from "../../components/BtnEdit";
import BtnPermissions from "../../components/BtnPermissions";
import UserShieldIcon from "../../components/UserShieldIcon";
import PermissionsModal from './UserPermissionModal';
import { GetPermissions } from './../../modules/auth/core/Auth';

const StyledUsers = styled.div`
    .card {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.15s ease-out;
    }

    .card:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f3f3f3;
    }

    .btn-icon {
        transition: transform 0.15s ease-out;
        margin-right: 10px;
    }

    .btn-icon:hover {
        transform: scale(1.1);
    }

    .text-end {
        padding-right: 15px !important;
        
    }

    svg {
        width: 20px;
        opacity: 0.7;
    }

    .superadmin svg {
        width: 100%;
        color: red;
        opacity: 1;
    }
    
    .superadmin, .admin, .user {
        height: 40px;

        svg {
            width: 100%;
            height: 100%;
            opacity: 1;
        }

        img {
            width: 100%;
            height: 100%;
            opacity: 1;
        }
    }

    .superadmin svg {
        color: red;
    }

    .admin svg {
        color: orange;
    }

`;

const UserList:FC = () => {
    const permissions = GetPermissions();

    const [selectedUser, setSelectedUser] = useState<any>();
    const [users, setUsers] = useState<any[]>([]);
    const [modal, showModal] = useState<boolean>(false);
    const [messageModal, showMessageModal] = useState<boolean>(false);
    const [deleteModal, showDeleteModal] = useState<boolean>(false);
    const [permissionModal, showPermissionModal] = useState(false);
    const [groupPermissions, setGroupPermissions] = useState<any>([]);

    function onDeleteUserClick(user:any) {
        setSelectedUser(user);
        showDeleteModal(true);
    }

    function onEditUserClick(user:any) {
        setSelectedUser(user);
        showModal(true);
    }

    function onPermissionsClick(user:any) {
        setSelectedUser(user);
        showPermissionModal(true);
    }

    function onUserCreated() {
        updateData();
        showModal(false);
        setSelectedUser(null);
    }

    function onUserUpdated() {
        updateData();
        showModal(false);
        setSelectedUser(null);
    }

    async function updateData() {
        const response = await API.listUsers();
        const users = response.data.filter((user:any) => {
            //if (user.is_superadmin) return false;
            ///if (user.is_viewer) return false;
            return true;
        });
        users.forEach((user:any) => {
            try {
                user.permissions = JSON.parse(user.permissions).sort((a: number, b: number) => a - b);
            } catch (e) {};
        })
        setUsers(users);
    }

    async function loadPermissions() {
        const groupPermissions = await API.getGroupPermissions();
        setGroupPermissions(groupPermissions.data);
    }

    const getPermissionsName = (ids:any) => {
        try {
            if (!ids || !Array.isArray(ids) || ids.length === 0) return '-';
        
            if (!groupPermissions || !Array.isArray(groupPermissions)) return '-';
        
            const names = ids
                .map(id => {
                    const permission = groupPermissions.find(p => p.id_feature_group == id);
                    return permission ? permission.name : null;
                })
                .filter(name => name !== null);
        
            return names.join(', ');
        } catch (e) {
            console.log(e);
        }
        return '-';
    };

    useEffect(() => {
        updateData(); 
        loadPermissions();
    }, []);

    return <StyledUsers>
        <PageTitle>Administração de Usuário</PageTitle>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={messageModal}>
            <MessageModal title='Função indisponível' description='Já foi cadastrado o limite de usuários máximo para essa empresa. Caso seja necessário mais usuários, entre em contato com nossa equipe.' onClose={() => {
                showMessageModal(false);
            }}/>
        </Modal>
        <Modal id='kt_modal_create_app' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={modal}>
            { <UserModal userData={selectedUser} onUserUpdated={() => { onUserUpdated() }} onUserCreated={() => { onUserCreated() }} onClose={() => {
                showModal(false);
                setSelectedUser(null);
            }} /> }
        </Modal>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-600px' show={permissionModal}>
                <PermissionsModal user={selectedUser} permissions={groupPermissions} onClose={(value:any) => {
                    showPermissionModal(false);
                    setSelectedUser(null);
                }} onChange={() => {
                    updateData();
                    showPermissionModal(false);
                    setSelectedUser(null);
                }}/>
            </Modal>
        <Modal id='kt_modal_delete_app' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={deleteModal}>
            <DeleteUserModal userData={selectedUser} onClose={() => { 
                showDeleteModal(false);
                setSelectedUser(null);  }} 
                onUserDeleted={() => { 
                    showDeleteModal(false);
                    setSelectedUser(null); 
                    updateData();
                }}
            />
        </Modal>
        <div className={`card shadow-sm`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    
                    <span className='text-muted mt-1 fw-semibold fs-7'>{users.length ? `${users.length} ${users.length == 1 ? 'Membro' : 'Membros'}` : ''}</span>
                </h3>
                <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Adicionar Usuário'>
                    <button onClick={() => { showModal(true) }} className='btn btn-sm btn-light-primary'>
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        Adicionar Usuário
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>Usuário</th>
                                <th className='min-w-140px'>E-mail</th>
                                <th className='min-w-120px'>Status</th>
                                <th className='min-w-200px'>Permissões</th>
                                <th className='min-w-100px text-end'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>{ users.map((user:any) => { return (
                            <tr key={user.guid}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-45px me-5'>
                                            <div className={user.is_superadmin ? 'superadmin' : user.is_admin ? 'admin' : 'user'}>
                                                { user.src ? 
                                                    <img src={user.src} alt='Foto de perfil' className='' /> :
                                                    <UserShieldIcon/>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                            <label className='text-dark fw-bold text-hover-primary fs-6'>{user.name}</label>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>{user.is_superadmin ? 'Super Admin' : user.is_admin ? 'Administrador' : user.is_viewer ? 'Visualizador' : 'Membro'}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{user.email}</span>
                                </td>
                                <td>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>{user.is_actived ? 'Ativo' : 'Desativado'}</span>
                                </td>
                                <td>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>{getPermissionsName(user.permissions)}</span>
                                </td>
                                <td>
                                    <div className='d-flex mr-4 justify-content-end flex-shrink-0'>
                                        {  permissions?.some(feature => feature.name.includes('users-permissions')) ? 
                                        <button onClick={() => { onPermissionsClick(user)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                            <BtnPermissions/>
                                        </button> : null }

                                        {  permissions?.some(feature => feature.name.includes('users-edit')) ? 
                                        <button onClick={() => { onEditUserClick(user)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                            <BtnEdit/>
                                        </button> : null }

                                        {  permissions?.some(feature => feature.name.includes('users-remove')) ? 
                                        <button onClick={() => {onDeleteUserClick(user)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                            <BtnTrash/>
                                        </button> : null }
                                    </div>
                                </td>
                            </tr>
                            )})}</>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </StyledUsers>

}

export default UserList;