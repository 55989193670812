import styled from "@emotion/styled";
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from "../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from 'react';
import * as API from '../../api';
import { Modal } from 'react-bootstrap';
import VouchersListModal from './../modals/VouchersListModal';
import UploadModal from './../modals/UploadStoreModal';
import ConfirmModal from './../modals/ConfirmModal';
import { GetPermissions } from './../../modules/auth/core/Auth';
import Select from 'react-select';
import IconAccept from './../../components/IconAccept';
import IconRemove from './../../components/IconRemove';

const ScreenStyle = styled.div`
    position: relative;

    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }
    
    .action-btn {
        transition: opacity 0.1s ease;
    }

    .action-btn:hover {
        opacity: 0.6;
    }

    .inactive-store {
        background-color: rgba(255, 0, 0, 0.1);
    }

    .mw-1200px {
        max-width: 1200px;
    }

    .select-filter {
        
    }
`;

type Props = {
    className?: string
}

const filterParticipantesOptions = [
    { value: '', label: 'Todos' },
    { value: 'active', label: 'Participantes' },
    { value: 'inactive', label: 'Não Participantes' }
]

const StoreScreen: React.FC<Props> = ({ className }) => {
    const permissions = GetPermissions();

    const [message, setMessage] = useState<string>('');
    const [confirmModal, showConfirmModal] = useState(false);
    const [confirmSelectedStore, setConfirmSelectedStore] = useState<any>(null);
    const [uploadModal, showUploadModal] = useState(false);
    const [uploadPromise, setUploadPromise] = useState<any>(null);
    const [voucherModal, showVoucherModal] = useState(false);
    const [companys, setCompanys] = useState<any>([]);
    const [selectedStore, setSelectedStore] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [counter, setCounter] = useState<any>(0);
    const [searchData, setSearchData] = useState<any>([]);
    const [resultsData, setResultsData] = useState<any>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [filterParticipantes, setFilterParticipantes] = useState<any>(filterParticipantesOptions[0]);
    
    

    useEffect(() => {
        loadStores();
    }, []);

    useEffect(() => {
        showConfirmModal(message != '');
    }, [message]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            searchRequest();
        }, 150);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, filterParticipantes]);

    useEffect(() => {
        if (searchTerm === '' && filterParticipantes.value == '') {
            setResultsData(companys);
        } else {
            setResultsData(searchData);
        }
    }, [companys, searchData]);

    const loadStores = async () => {
        setIsLoading(true);
        const response = await API.listStores(0);
        setCompanys(response.data);
        setCounter(response.count);
        setIsLoading(false);
    }

    const searchRequest = async () => {
        if (searchTerm === '' && filterParticipantes.value == '') {
            setSearchData([]);
            return;
        }
        setIsLoading(true);
        const response = await API.searchStore(searchTerm, filterParticipantes.value);
        if (response.success) setSearchData(response.data);
        setIsLoading(false);
    }

    const updateStatus = async(company:any, status:boolean) => {
        if (company.is_actived == status) return;
        const response = await API.updateStoreStatus(company, status);
        if (response.success) {
            const updatedCompanies = companys.map((comp:any) =>
                comp.IDLojista === company.IDLojista 
                    ? { ...comp, is_actived: status } 
                    : comp
            );
            setCompanys(updatedCompanies);

            const updateSearchCompanies = searchData.map((comp:any) =>
                comp.IDLojista === company.IDLojista 
                    ? { ...comp, is_actived: status } 
                    : comp
            );
            setSearchData(updateSearchCompanies);
        }
    };

    useEffect(() => {
        showVoucherModal(selectedStore !== null);
    }, [selectedStore]);

    useEffect(() => {
        showUploadModal(uploadPromise !== null);
    }, [uploadPromise]);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: any) => {
        const file:any = event.target.files[0];
        if (file) {
            const promise = API.uploadStores(file);
            setUploadPromise(promise);
        }
    };

    return (
        <ScreenStyle>
            <PageTitle>Lojas Participantes</PageTitle>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-1300px'
                show={voucherModal} onHide={() => setSelectedStore(null)} backdrop="static">
                <VouchersListModal store={selectedStore} onClose={() => setSelectedStore(null)}/>
            </Modal>
            <Modal id='upload_modal' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-1300px'
                show={uploadModal} onHide={() => setUploadPromise(null)} backdrop="static">
                <UploadModal promise={uploadPromise} onClose={(update:boolean = false) => {
                        setUploadPromise(null);
                        if (update) loadStores();
                    }}
                />
            </Modal>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-600px' show={confirmModal}>
                <ConfirmModal title={`${confirmSelectedStore?.RazaoSocial}`} onClose={(value:any) => {
                    if (value) updateStatus(confirmSelectedStore, !(confirmSelectedStore.is_actived == '1'));
                    setConfirmSelectedStore(null);
                    setMessage('');
                }} message={message}/>
            </Modal>
            <div className="card card-custom gutter-b p-10">
                <h3 className="card-title align-items-start flex-column">
                {!isLoading ? (
                    <span className="card-label fw-bold fs-3 mb-1">
                    Lojas cadastradas: {counter}
                    </span>
                ) : null}
                </h3>
                <div className="mb-4"></div>
                { permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-add')) ? 
                <div className='d-flex py-1 justify-content-end'>
                    <div className='mb-4'>
                        { /*<a href='#' className='disabled btn btn-primary btn-sm btn-light'>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                            Cadastrar Loja
                        </a> */ }
                        <a href='#' className='btn btn-primary btn-sm btn-light ms-2' onClick={handleUploadClick}>
                            Alterar Status de Lojas
                        </a>
                        <a href='https://sadataliteracyexternal.blob.core.windows.net/insight-experience/static/52361195-8d27-4df1-8b03-59e389e90506.xlsx' className='btn btn-secondary btn-sm btn-light ms-2'>
                            Baixar modelo
                        </a>
                        <input  
                            ref={fileInputRef} 
                            type="file" 
                            accept=".xlsx" 
                            style={{ display: "none" }} 
                            onChange={handleFileChange} 
                        />
                    </div>
                </div> : null }
                
                <div className="d-flex monitor-alert-box">
                    <div className="form-item w-100">
                        <label htmlFor="" className="form-label">Sistema de Busca</label>
                        <div className="d-flex w-100">
                            <input
                                placeholder="Digite aqui CNPJ ou Razão Social"
                                type="text"
                                defaultValue={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-input form-control"
                            />
                            <span className="search-icon svg-icon svg-icon-1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                </svg>
                            </span>  
                            <Select 
                                className=" w-250px form-select-solid form-item ms-4 select-filter" 
                                value={filterParticipantes} 
                                placeholder={'Filtro por Status'}
                                options={filterParticipantesOptions}
                                onChange={(e) => {
                                    setFilterParticipantes(e);
                                }}
                            />
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            <div className={`card mt-4`}>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        { !isLoading ? resultsData.length ? (
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th className='min-w-150px'>CNPJ</th>
                                        <th className='min-w-140px'>Razão Social</th>
                                        <th className='min-w-120px'>Status</th>
                                        <th className='min-w-100px text-end'>{ permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-edit-status')) ? 'Ações' : 'Detalhes'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resultsData.map((company: any) => (
                                        <tr key={company.IDLojista} className={company.is_actived == '1'? '' : 'inactive-store'}>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <span className='text-dark fw-bold fs-6'>
                                                            {company.CNPJ}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-semibold d-block fs-6'>
                                                    {company.RazaoSocial}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-semibold d-block fs-6'>
                                                    {company.is_actived == '1' ? 'Participante' : 'Não Participante'}
                                                </span>
                                            </td>
                                             
                                            <td>
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                    {company.vouchers > 0 && (
                                                        <a
                                                            href='#'
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 action-btn'
                                                            onClick={() => setSelectedStore(company)}
                                                        >
                                                            <KTSVG path='/media/icons/duotune/menu/coupon2.svg' className='svg-icon-3 me-2' />
                                                            {company.vouchers}
                                                        </a>
                                                    )}
                                                    { permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-edit-status')) ?
                                                    <>
                                                    <a href='#' className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 action-btn ${company.is_actived == '1' ? 'disabled' : ''}`} onClick={() => {
                                                        setMessage(`Deseja alterar o status para PARTICIPANTE?`);
                                                        setConfirmSelectedStore(company)}}>
                                                        <IconAccept className={'svg-icon-3'}></IconAccept>
                                                    </a>
                                                    <a href='#' className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm action-btn ${!(company.is_actived == '1') ? 'disabled' : ''}`} onClick={() => {
                                                        setMessage(`Deseja alterar o status para NÃO PARTICIPANTE?`);
                                                        setConfirmSelectedStore(company)}}>
                                                        <IconRemove className={'svg-icon-3'}></IconRemove>
                                                        
                                                    </a>
                                                    </> : null }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) :
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Nenhum registro encontrado</p>
                        </div> :
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                            <div className="preloader"></div>
                        </div> }
                    </div>
                </div>
            </div>
        </ScreenStyle>
    );
}

export default StoreScreen;