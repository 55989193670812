type GroupNameInputProps = {
    value: string;
    onChange: (value: string) => void;
  };
  
  export const GroupNameInput = ({ value, onChange }: GroupNameInputProps) => (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">Nome do Grupo</label>
      <input
        type="text"
        placeholder="Ex: Administrador, Editor, Visualizador..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-4 py-3 rounded-xl border-2 border-gray-100 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50 hover:bg-white"
      />
    </div>
  );