import { Table } from './Table';

const columns = [
  { key: 'DataHoraPedidoAtivacao', label: 'Data Pedido' },
  { key: 'IDPedidoAtivacao', label: 'ID Pedido' },
  { key: 'CNPJ', label: 'CNPJ' },
  { key: 'RazaoSocial', label: 'Razão Social' },
  { key: 'IDRegraPromocaoAtivacao', label: 'ID Regra Promoção' },
  { key: 'NomePromocao', label: 'Nome Promoção' },
  { key: 'BU', label: 'BU' },
  { key: 'Tamanho', label: 'Tamanho' },
  { key: 'NomeRegional', label: 'Regional' },
];

export const DataTable = ({ data }: { data: any[] }) => (
  <div className="overflow-x-auto">
    <Table columns={columns} data={data} />
  </div>
);