import { Table } from './Table';

const columns = [
  { key: 'IDRegraPromocao', label: 'ID Regra Promoção' },
  { key: 'NomeCupom', label: 'Nome Cupom' },
  { key: 'DataInicioVigenciaCupom', label: 'Data Início' },
  { key: 'DataFimVigenciaCupom', label: 'Data Término' },
  { key: 'TotalLojistaHabilitados', label: 'Lojistas Habilitados' },
  { key: 'TotalLojistaQueUtilizaram', label: 'Lojistas que Utilizaram' },
  { key: 'PorcentagemQueUtilizaram', label: 'Porcentagem que Utilizaram' },
  { key: 'ValorUtilizadoCupom', label: 'Valor Utilizado' },
];

export const DataTable = ({ data }: { data: any[] }) => (
  <div className="overflow-x-auto">
    <Table columns={columns} data={data} />
  </div>
);