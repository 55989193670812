import { Table } from './Table';
import { Search } from 'lucide-react';

const columns = [
  { key: 'MesAtivacao', label: 'Mês Ativação' },
  { key: 'CNPJLojista', label: 'CNPJ' },
  { key: 'RazaoSocial', label: 'Razão Social' },
  { key: 'ValorLoyalty', label: 'Valor Cashback' },
  { key: 'actions', label: 'Detalhes' },
];

type DataTableProps = {
  data: any[];
  onViewDetails: (item: any) => void;
};

export const DataTable = ({ data, onViewDetails }: DataTableProps) => (
  <div className="overflow-x-auto">
    <Table 
      columns={columns} 
      data={data} 
      actions={(item) => (
        <button
          onClick={() => onViewDetails(item)}
          className="p-2 text-gray-400 hover:text-blue-500 hover:bg-blue-50 rounded-lg transition-colors"
        >
          <Search className="w-5 h-5" />
        </button>
      )}
    />
  </div>
);