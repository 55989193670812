import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import BtnClose from './../../components/BtnClose';
import * as API from '../../api';
import {sha256} from 'crypto-hash';
import { messages } from './../utils/ErrorsText';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import { getUserData } from '../../modules/auth/core/Auth';
import CropperComponent from '../../pages/utils/ImageCropper';

const StyledForm = styled.div`
    
    .form-item {
        display:flex;
        width: 100%;
        gap: 10px;
        margin-bottom: 10px;
        p {
            width: 150px;
            margin:auto;
            text-align: right;
        }
    }
`;

const StyledModal = styled.div`
    background-color: white;

    .card-header {
        align-items: center;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .remove-user-title {
        margin:20px !important;
    }

    .status-item {
        margin-right: 30px;
        
        label {
            margin-right: 10px;
        }
    }

    .mailling-item {
        margin-right: 30px;
        
        label {
            margin-right: 10px;
        }
    }

    .viewer-item {
        margin-right: 30px;
        
        label {
            margin-right: 10px;
        }
    }

    .cropper {
        padding-top: 10px;
        max-width: 300px;
    }
`;

interface Props {
    userData?:any,
    onUserCreated: Function,
    onUserUpdated: Function,
    onClose: Function
}

const UserModal:FC<Props> = (props:Props) => {
    const [loading, setLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState<string>(props.userData?.name || '');
    const [email, setEmail] = useState<string>(props.userData?.email || '');
    const [isAdmin, setIsAdmin] = useState<number>(props.userData ? props.userData.is_admin : 0);
    const [isActived, setIsActived] = useState<number>(props.userData ? props.userData.is_actived : 1);
    const [isMailling, setIsMailling] = useState<number>(props.userData ? props.userData.is_mailling : 1);
    const [isViewer, setIsViewer] = useState<number>(props.userData ? props.userData.is_viewer : 0);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [croppedImage, setCroppedImage] = useState('');
    const [uploadResponse, setUploadResponse] = useState<any>();
    const [showCropper, setShowCropper] = useState(false);

    function onImageSelect(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setImageFile(e.target.files[0]);
            setShowCropper(true);
        }
    }

    async function onSubmit() {
        const statusResponse = validationStatus();
        setStatus(statusResponse);
        if (statusResponse !== '') return;
        setLoading(true);
        try {
            if (props.userData) {
                const response = await API.updateUser({
                    user_guid:props.userData.guid,
                    name:name,
                    email:email,
                    password: password.length ? await sha256(password) : '',
                    id_upload: uploadResponse ? uploadResponse.id_upload : undefined,
                    is_actived:isActived,
                    is_mailling:isMailling,
                    is_viewer:isViewer,
                    is_admin:isAdmin,
                    company_guid:props.userData.company_guid
                });
                if (!response.success) {
                    setStatus(messages[response.error]);
                    setLoading(false);
                    return;
                }
                props.onUserUpdated();
            } else {
                let companyGuid = isSuperAdmin() ? localStorage.getItem("current_company") : getUserData().company_guid;
                
                const response = await API.createUser({
                    name:name,
                    email:email,
                    password: await sha256(new Date().getTime().toString()),
                    id_upload: uploadResponse ? uploadResponse.id_upload : undefined,
                    is_actived:isActived,
                    is_mailling:isMailling,
                    is_viewer:isViewer,
                    is_admin:isAdmin,
                    company_guid:companyGuid
                });
                if (!response.success) {
                    setStatus(messages[response.error]);
                    setLoading(false);
                    return;
                }
                props.onUserCreated();
            }
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    function validateEmail() {
        if (props.userData && props.userData.email === email) return '';
        if (/^[\w-\.+\-]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) return 'is-valid';
        return 'is-invalid';
    }

    function validateName() {
        if (name.length < 5) return 'is-invalid';
        if (props.userData && props.userData.name !== name) return 'is-valid';
        return '';
    }

    function validatePassword() {
        if (confirmPassword.length && !password.length) return 'is-invalid';
        if (!password.length) return '';
        if (password.length < 6) return 'is-invalid';
        return 'is-valid';
    }

    function validateConfirmPassword() {
        if (!password.length || !confirmPassword.length) return '';
        if (confirmPassword === password) return 'is-valid';
        return 'is-invalid';
    }

    function validationStatus() {
        if (!/^[\w-\.+\-]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) return 'E-mail inválido';
        if (name.length < 5) return 'Nome de usuário deve conter no mínimo 5 caracteres';
        // if (confirmPassword.length && !password.length || (confirmPassword.length && password.length && password != confirmPassword)) return 'Senha e confirmação de senha devem ser iguais';
        // if (password.length && password.length < 6) return 'A senha deve conter no mínimo 6 caracteres';
        return ''
    }
    
    return (
    <StyledModal className="card mb-5 mb-xl-10">
            <div className='card-header border-0'>
                <div className='card-title m-0'>
                    
                    <h3>{props.userData ? 'Editar Usuário' : 'Adicionar Usuário'}</h3>
                </div>

                <BtnClose onClick={() => props.onClose()} />
            </div>
        
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative ">
                <div className="me-7 mb-4 justify-content-center">
                    {!croppedImage && (
                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img src={uploadResponse ? uploadResponse.path : props.userData?.src ? props.userData.src : toAbsoluteUrl('/media/icons/duotune/general/gen049.svg')} alt="Meu Perfil"/>
                    </div>
                    )}
                    {croppedImage && (
                        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img src={croppedImage} alt="Perfil" />
                        </div>
                    )}
                    <input type="file" accept="image/*" onChange={onImageSelect} style={{ display: 'none' }} ref={inputRef}/>
                    <div className='row mt-2 p-0 align-center'>
                        <button onClick={() => inputRef.current?.click()} className="btn btn-primary">Alterar imagem</button>
                    </div>
                    <div className='cropper'>
                        {showCropper && (
                        <CropperComponent imageFile={imageFile} setCroppedImage={setCroppedImage} setShowCropper={setShowCropper} setUploadResponse={setUploadResponse}/>
                    )}
                    </div>
                </div>
                
            </div>

                <div className='flex-grow-1'>
                
                <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                        <StyledForm>
                            <div className='form-item'>
                                <p>E-mail: </p>
                                <input type='text' className={`form-control form-control-lg form-control-solid ${validateEmail()}`} name='email' placeholder='E-mail' defaultValue={email} onChange={(e) =>
                                    setEmail(e.currentTarget.value)
                                }/>
                                
                            </div>
                            <div className='form-item'>
                                <p>Nome: </p>
                                <input type='text' className={`form-control form-control-lg form-control-solid ${validateName()}`} name='name' placeholder='Nome' defaultValue={name} onChange={(e) =>
                                    setName(e.currentTarget.value)
                                }/>
                                
                            </div>
                            
                            <div className='form-item'>
                                <p>Acesso: </p>
                                <select className="form-select form-select-lg form-select-solid" defaultValue={isAdmin} onChange={({ target: { value } }) => { setIsAdmin(value == '1' ? 1 : 0) }}>
                                    <option value='0'>Visualizador</option>
                                    <option value='1'>Administrador</option>
                                </select>
                            </div>
                            <div className='form-item'>
                                <p>Status: </p>
                                <div className='form-control d-flex'>
                                    <div className='status-item'>
                                        <label>Ativo</label>
                                        <input className='form-check-input' type='radio' name='status' value='Ativo' checked={ isActived == 1 } onChange={({ target: { value } }) => { setIsActived(value == 'Ativo' ? 1 : 0) }}/>
                                    </div>                                
                                    <div className='status-item'>
                                        <label>Inativo</label>
                                        <input className='form-check-input' type='radio' name='status' value='Inativo' checked={ isActived == 0 } onChange={({ target: { value } }) => { setIsActived(value == 'Ativo' ? 1 : 0) }}/>
                                    </div>
                                </div>
                                
                            </div>
                            { /* <div className='form-item'>
                                <p>Mailling: </p>
                                <div className='form-control d-flex'>
                                    <div className='mailling-item'>
                                        <label>Sim</label>
                                        <input className='form-check-input' type='radio' name='mailling' value='Sim' checked={ isMailling === 1 } onChange={({ target: { value } }) => { setIsMailling(value == 'Sim' ? 1 : 0) }}/>
                                    </div>                                
                                    <div className='mailling-item'>
                                        <label>Não</label>
                                        <input className='form-check-input' type='radio' name='mailling' value='Não' checked={ isMailling === 0 } onChange={({ target: { value } }) => { setIsMailling(value == 'Sim' ? 1 : 0) }}/>
                                    </div>
                                </div>                                
                            </div> */ }
                            { /* <div className='form-item'>
                            { isSuperAdmin() ? <div className='form-item'>
                                <p>Visualizador: </p>
                                <div className='form-control d-flex'>
                                    <div className='viewer-item'>
                                        <label>Sim</label>
                                        <input className='form-check-input' type='radio' name='viewer' value='Sim' checked={ isViewer === 1 } onChange={({ target: { value } }) => { setIsViewer(value === 'Sim' ? 1 : 0) }}/>
                                    </div>                                
                                    <div className='viewer-item'>
                                        <label>Não</label>
                                        <input className='form-check-input' type='radio' name='viewer' value='Não' checked={ isViewer === 0 } onChange={({ target: { value } }) => { setIsViewer(value === 'Sim' ? 1 : 0) }}/>
                                    </div>
                                </div>                                
                            </div> : <></> 
                            </div>} */ }
                            { /* <div className='form-item'>
                                <p>Senha: </p>
                                <input type='password' className={`form-control form-control-lg form-control-solid ${validatePassword()}`} name='password' placeholder='Senha' defaultValue={password} onChange={(e) =>
                                    setPassword(e.currentTarget.value)
                                }/>
                                
                            </div>
                            <div className='form-item'>
                                <p>Confirmar Senha: </p>
                                <input type='password' className={`form-control form-control-lg form-control-solid ${validateConfirmPassword()}`} name='confirmPassword' placeholder='Confirmar Senha' defaultValue={confirmPassword} onChange={(e) =>
                                    setConfirmPassword(e.currentTarget.value)
                                }/>
                                
                            </div> */ }
                        </StyledForm>
                        </div>
                    </div>
                    { status != '' && (
                    <div className='text-center'>
                        <div className='alert alert-danger w-100 text-center'>
                            <div className='alert-text font-weight-bold'>{status}</div>
                        </div>
                    </div>)}
                    <div className='text-center'>
                        <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary w-50 mb-5 mt-5' disabled={validationStatus() != ''} onClick={() => { onSubmit() }}>
                        {!loading && <span className='indicator-label'>{ props.userData ? 'Atualizar dados' : 'Criar usuário'}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Aguarde por favor...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                        </button>
                        </div>
                    </div>
                    
                </div>
                
                
            </div>
            
        </div>
    </StyledModal>
    )
}

export default UserModal
