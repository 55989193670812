import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.div`
    .circle-style{fill:#25AE88;}
    .polyline-style{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}


`;

interface Props {
    onClick?: Function;
    className?: string;
}

const IconAccept: React.FC<Props> = ({ className, onClick }: Props) => {
    return (
        <StyledButton className={className}>
            <div>
                <div>
                    <span className="svg-icon svg-icon-1">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                            viewBox="0 0 50 50" xmlSpace="preserve">
                            <circle className="circle-style" cx="25" cy="25" r="25"/>
                            <polyline className="polyline-style" points="38,15 22,33 12,25"/>
                        </svg>
                    </span>
                </div>
            </div>
        </StyledButton>
    );
};
export default IconAccept;

