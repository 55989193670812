import { Edit2, Trash2, Lock } from 'lucide-react';

type Permission = {
  id_feature: string;
  title: string;
};

type PermissionCardProps = {
  group: {
    name: string;
    data: string[];
  };
  permissions: Permission[];
  onEdit: (group: any) => void;
  onDelete: (group: any) => void;
};

export const PermissionCard = ({ group, permissions, onEdit, onDelete }: PermissionCardProps) => {
  const permissionMap = new Map(permissions.map(p => [p.id_feature, p]));

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden transition-all duration-200 hover:shadow-md">
      <div className="p-6 border-b border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <Lock className="w-5 h-5 text-blue-500" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900">{group.name}</h3>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => onEdit(group)}
              className="p-2 text-gray-400 hover:text-blue-500 hover:bg-blue-50 rounded-lg transition-colors"
            >
              <Edit2 className="w-5 h-5" />
            </button>
            <button
              onClick={() => onDelete(group)}
              className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-lg transition-colors"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {group.data.map((permissionId) => {
            const permission = permissionMap.get(permissionId);
            if (!permission) return null;
            
            return (
              <div
                key={permission.id_feature}
                className="flex items-center space-x-3 p-3 rounded-lg bg-gray-50 hover:bg-blue-50 transition-colors"
              >
                <input
                  type="checkbox"
                  checked
                  disabled
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-gray-700">{permission.title}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};