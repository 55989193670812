/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import styled from '@emotion/styled';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import * as API from '../../../../app/api';
import { getUserData } from '../../../../app/modules/auth'
import Select from 'react-select';
import { M } from 'chart.js/dist/chunks/helpers.core';

const StyledSelect = styled.div`
	display: flex;
    padding: 7px;
	width: 370px;
	position:relative;

	p {
		font-size:16px;
		text-align:center;
		
		margin: auto;
		padding-right: 10px;
		cursor: context-menu;
	}
`;

export function HeaderWrapper() {
  	const {config, classes, attributes} = useLayout()
  	const {header, aside} = config
	const [companys, setCompanys] = useState<any[]>([]);
	const [company, setCompany] = useState<string>('');
	const [features, setFeatures] = useState<any[]|undefined>(undefined);

	useEffect(() => {
		if (!getUserData()?.is_superadmin) return;
		const currentCompany = localStorage.getItem("current_company");
		localStorage.setItem("current_company", currentCompany ? currentCompany : getUserData().company_guid);
		setCompany(localStorage.getItem("current_company") as string);
        
    }, []);

	const selectedCompanyOption = useMemo(() => {
		const matchingCompany = companys.find(c => c.guid === company);
		if (matchingCompany) {
			return { value: matchingCompany.guid, label: `${matchingCompany.name} [${matchingCompany.company_type}]` }
		}
	}, [companys, company]);

	
	
	return (
		<div
			id='kt_header'
			className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
			{...attributes.headerMenu}
		>
		
		
		<div
			className={clsx(
			classes.headerContainer.join(' '),
			'd-flex align-items-stretch justify-content-between'
			)}
		>
			<DefaultTitle />
			{aside.display && (
			<div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
				<div
				className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
				id='kt_aside_mobile_toggle'
				>
				<KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
				</div>
			</div>
			)}
			
			{!aside.display && (
			<div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
				<Link to='/dashboard' className='d-lg-none'>
				<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-wide.png')} className='h-30px' />
				</Link>
			</div>
			)}

			{aside.display && (
			<div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
				<Link to='/' className='d-lg-none'>
				<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-wide.png')} className='h-30px' />
				</Link>
			</div>
			)}

			<div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>

			{header.left === 'menu' && (
				<div className='d-flex align-items-stretch' id='kt_header_nav'>
				<Header />
				</div>
			)}

			{header.left === 'page-title' && (
				<div className='d-flex align-items-center' id='kt_header_nav'>
				<DefaultTitle />
				</div>
			)}
			
			<div className='d-flex align-items-stretch flex-shrink-0'>
				{ companys?.length ? <StyledSelect>
					
				<Select 
					className="form-select-lg w-100 form-select-solid form-item"
					placeholder={"Selecione uma empresa"}
					options={companys.map(c => ({ value: c.guid, label: `${c.name} [${c.company_type}]` }))}
					value={selectedCompanyOption}
					onChange={(c:any) => {
						setCompany(c.value);
						localStorage.setItem("current_company", c.value);
							localStorage.setItem("current_company", c.value);
							if (document.location.href.indexOf('dashboard') != -1) {
								document.location.href = "/home";
							} else if (document.location.href.indexOf('alerts') != -1) {
								document.location.href = "/home";
							} else {
								document.location.href = document.location.href;
							}
						}}
					/>
					
					</StyledSelect>	
				: <></>}
				
					<Topbar />
			</div>
			
			</div>
			{/* end::Wrapper */}
		</div>
		</div>
  )
}
