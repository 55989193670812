import styled from "@emotion/styled";
import { Modal } from 'react-bootstrap';
import { PageTitle } from "../../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from 'react';
import * as API from '../../../api';
import ConfirmModal from '../../modals/ConfirmModal';
import { GetPermissions } from './../../../modules/auth/core/Auth';

const ScreenStyle = styled.div`
    position: relative;

    .new-rules-button {
        right: 0px;
    }
    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }

    .action-btn {
        transition: opacity 0.1s ease;
    }

    .action-btn:hover {
        opacity: 0.6;
    }

    .inactive-item {
        background-color: rgba(255, 0, 0, 0.1);
    }

    .edited {
        background-color: orange;
    }
`;

type Props = {
    className?: string
}

const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    return new Date(dateString).toLocaleString('pt-BR', options);
}

function brlFormat(value: any) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(value);
}

const PriceScreen: React.FC<Props> = ({ className }) => {
    const permissions = GetPermissions();

    const [message, setMessage] = useState<string>('');
    const [confirmModal, showConfirmModal] = useState(false);
    const [selectedList, setSelectedList] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>([]);
    const [tamanhos, setTamanhos] = useState<any>([]);
    const [quantities, setQuantities] = useState<any>([]);
    const [isEditable, setIsEditable] = useState(false);
    const [editedValues, setEditedValues] = useState<Map<number, string>>(new Map());
    const originalValuesRef = useRef<Map<number, string>>(new Map());

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        showConfirmModal(message != '');
    }, [message]);

    const getData = async () => {
        setIsLoading(true);
        const response = await API.getPrecoList();
        const sizes: any = [];
        const qtySet:any = new Set<number>();

        const dataMap = new Map<number, any>();
        response.data.forEach((item: any) => {
            if (!sizes.includes(item.TamanhoLista)) {
                sizes.push(item.TamanhoLista);
            }
            qtySet.add(item.Quantidade);
            if (!dataMap.has(item.Quantidade)) {
                dataMap.set(item.Quantidade, []);
            }
            dataMap.get(item.Quantidade).push(item);
            originalValuesRef.current.set(item.IDPreco, item.value.toString());
        });

        setTamanhos(sizes);
        setQuantities([...qtySet].sort((a, b) => a - b));

        const rows = [...qtySet].sort((a, b) => a - b).map(qty => dataMap.get(qty));
        setData(rows);
        setIsLoading(false);
    }

    const handleSwitchChange = (checked: boolean) => {
        setIsEditable(checked);
        if (!checked) {
            setEditedValues(new Map());
        }
    }

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const newValue = e.target.value;
        const originalValue = originalValuesRef.current.get(id);

        const updatedValues = new Map(editedValues);
        if (newValue === originalValue) {
            updatedValues.delete(id);
        } else {
            updatedValues.set(id, newValue);
        }
        setEditedValues(updatedValues);
    }

    const hasChanges = () => {
        return Array.from(editedValues).some(([id, value]) => {
            const originalItem = data.flat().find((item: any) => item.IDPreco === id);
            return originalItem && originalItem.value !== value;
        });
    }

    const handleConfirm = () => {
        setMessage('Ao continuar, os novos valores já contarão para o mês vigente. Tem certeza que deseja confirmar?');
    }

    const updateData = async() => {
        const arrayData = Array.from(editedValues, ([id, value]) => ({ id, value: parseInt(value) }));
        await API.updatePrices({data:arrayData});
        setIsEditable(false);
        getData();
        
    }

    return (
        <ScreenStyle>
            <PageTitle>Tabela de Valores</PageTitle>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-600px' show={confirmModal}>
                <ConfirmModal title={`ATENÇÃO!`} onClose={(value:any) => {
                    if (value) updateData();
                    setMessage('');
                }} message={message}/>
            </Modal>
            { permissions?.some(feature => feature.name.includes('cashback-tabela-valores-edit')) ?  
            <div className="d-flex justify-content-end mb-4">
                <label>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <label className="form-check-label me-5" htmlFor="flexSwitchDefault">Alterar Valores</label>
                        <input className="form-check-input" type="checkbox" checked={isEditable} defaultChecked={isEditable} onChange={(e) => { handleSwitchChange(e.target.checked)}} id="flexSwitchDefault"/>
                    </div>
                </label>
            </div> : null }
            <div className={`card mt-4`}>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        { !isLoading ? 
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-50px text-center'>BUs</th>
                                    { tamanhos.map((item: any, index: number) => (
                                        <th key={index} className='min-w-200px text-center'>{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                            { quantities.map((qty: number, rowIndex: number) => (
                                <tr key={`row_${rowIndex}`}>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold fs-6'>
                                            {qty}
                                        </span>
                                    </td>
                                    { data[rowIndex]?.map((item: any, colIndex: number) => (
                                        <td key={colIndex} className="text-center">
                                            {!isEditable ? (
                                                <span className='text-dark fw-semibold fs-6'>
                                                    {brlFormat(item.value)}
                                                </span>
                                            ) : (
                                                <input 
                                                    type="text" 
                                                    className={`form-control text-center ${editedValues.get(item.IDPreco) && editedValues.get(item.IDPreco) !== originalValuesRef.current.get(item.IDPreco) ? 'edited' : ''}`}
                                                    value={editedValues.get(item.IDPreco) ?? item.value.toString()}
                                                    onChange={e => handleValueChange(e, item.IDPreco)}
                                                />
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table> :
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                            <div className="preloader"></div>
                        </div> }
                    </div>
                    {isEditable && (
                        <div className="mt-4 d-flex justify-content-end">
                            <button className="btn btn-danger warning me-5" onClick={() => { setIsEditable(false) }}>
                                Cancelar
                            </button>
                            <button className="btn btn-primary" onClick={handleConfirm} disabled={!hasChanges()}>
                                Confirmar
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </ScreenStyle>
    )
}

export default PriceScreen;