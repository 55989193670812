import { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import * as API from '../../api';
import { PageTitle } from '../../../_metronic/layout/core';
import ConfirmModal from '../modals/ConfirmModal';
import PermissionsModal from './PermissionModal';
import { EmptyState } from './EmptyState';
import { LoadingState } from './LoadingState';
import { PermissionCard } from './PermissionCard';

type Props = {
  className?: string;
};

const PermissionsScreen: React.FC<Props> = ({ className }) => {
  const [message, setMessage] = useState<string>('');
  const [permissionModal, showPermissionModal] = useState(false);
  const [confirmModal, showConfirmModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [permissions, setPermissions] = useState<any>([]);
  const [groupPermissions, setGroupPermissions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    showConfirmModal(message !== '');
  }, [message]);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const [permissionsRes, groupPermissionsRes] = await Promise.all([
        API.getPermissions(),
        API.getGroupPermissions(),
      ]);

      setPermissions(permissionsRes.data);
      setGroupPermissions(
        groupPermissionsRes.data.map((item: any) => ({
          ...item,
          data: JSON.parse(item.data),
        }))
      );
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewGroup = () => {
    setSelectedItem(null);
    showPermissionModal(true);
  };

  const handleEditGroup = (group: any) => {
    setSelectedItem(group);
    showPermissionModal(true);
  };

  const handleDeleteGroup = (group: any) => {
    setSelectedItem(group);
    setMessage(
      'Deseja remover esse grupo de permissões? Caso exista usuários vinculados a ele, eles serão afetados por essa ação.'
    );
  };

  const removeItem = async (item: any) => {
    setIsLoading(true);
    try {
      await API.removeGroupPermissions(item);
      await loadData();
    } catch (error) {
      console.error('Error removing group:', error);
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8 flex items-center justify-between">
          <PageTitle>Grupo de Permissões</PageTitle>
          <button
            onClick={handleNewGroup}
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 font-medium shadow-sm hover:shadow"
          >
            <Plus className="w-5 h-5 mr-2" />
            Criar grupo de permissões
          </button>
        </div>

        {isLoading ? (
          <LoadingState />
        ) : groupPermissions.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {groupPermissions.map((group: any) => (
              <PermissionCard
                key={group.id_feature_group}
                group={group}
                permissions={permissions}
                onEdit={handleEditGroup}
                onDelete={handleDeleteGroup}
              />
            ))}
          </div>
        )}
      </div>

      {/* Modals */}
      {permissionModal && (
        <PermissionsModal
          data={permissions}
          group={selectedItem}
          onClose={() => {
            showPermissionModal(false);
            setSelectedItem(null);
          }}
          onChange={() => {
            loadData();
            showPermissionModal(false);
            setSelectedItem(null);
          }}
        />
      )}

      {confirmModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <ConfirmModal
            title={`Grupo: ${selectedItem?.name}`}
            message={message}
            onClose={(value: boolean) => {
              if (value) removeItem(selectedItem);
              setSelectedItem(null);
              setMessage('');
            }}
          />
        </div>
      )}
    </>
  );
};

export default PermissionsScreen;