/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useAuth, getUserData, isAdmin, isSuperAdmin, GetPermissions} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
	const permissions = GetPermissions();
	const { logout } = useAuth()

    useEffect(() => {
        
    }, []);

	const onLogoutClick = ():any => {
		onMenuClick('Sair');
		logout();
	}

	const onMenuClick = (title:string):any => {
		window.gtag('event', 'click', {
			'event_category': 'Header',
			'event_label': title
		});
	}

	return (
    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px' data-kt-menu='true'>
		<div className='menu-item px-3'>
			<div className='menu-content d-flex align-items-center px-3'>
				<div className='symbol symbol-50px me-5'>
					<img alt='Logo' src={getUserData().src ? getUserData().src : toAbsoluteUrl('/media/icons/duotune/general/gen049.svg')} />
				</div>
			<div className='d-flex flex-column'>
				<div className='fw-bolder d-flex align-items-center fs-5'>
					{getUserData()?.name}
					<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{isSuperAdmin() ? 'Super Admin' : isAdmin() ? 'Admin' : 'Usuário'}</span>
				</div>
				<a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
					{getUserData()?.email}
				</a>
			</div>
		</div>
	</div>
	<div className='separator my-2'></div>
		<div className='menu-item px-5'>
			<Link to={'/profile'} className='menu-link px-5' onClick={onMenuClick('Meu Perfil')}>Meu Perfil</Link>
		</div>
		{  permissions?.some(feature => feature.name.includes('users-view')) ? 
		<div className='menu-item px-5'>
			<Link to={'/users'} className='menu-link px-5' onClick={onMenuClick('Usuários')}>Usuários</Link>
		</div> : null }
      	<div className='separator my-2'></div>
		<div className='menu-item px-5'>
			<a onClick={onLogoutClick} className='menu-link px-5'>Sair</a>
		</div>
    </div>
  	)
}

export {HeaderUserMenu}
