import { FC, useState, useEffect } from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import styled from '@emotion/styled';

const Style = styled.div`
  .modal-container {
    width: 100%;
    max-height: 70vh; /** Add this to control the height */
    overflow-y: auto; /** Add this to enable scrolling inside the modal */
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }

    .inactive-item {
        background-color: rgba(255, 0, 0, 0.1);
    }
`;

type Props = {
    onClose: Function,
    voucher?: any,
    jobId?: String
}

const brlFormat = (value: any) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(value);
}

const StoreListModal: FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [url, setURL] = useState(null);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const limit = 2000000;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //loadVouchersList();
    }, []);

    const loadList = async() => {
        const response = await API.getStoresByVoucher(props.voucher.IDVoucher);
        setData(response.data);
        setIsLoading(false);
    }

    useEffect(() => {
      if (props.voucher) {
        loadList();
      }
    }, [props.voucher]);

    const formatDate = (dateString: string): string => {
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
    
        return new Date(dateString).toLocaleString('pt-BR', options);
    }

    return (
        <Style>
            <div className='modal-container text-center justify-content-center'>
                <div className='px-7 py-5 d-flex text-center justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>{props.voucher ? `Cupom: ${props.voucher.IDVoucher} - ${props.voucher.DescricaoCupom}` : ''}</div>
					<div>
						<BtnClose onClick={() => props.onClose()} />
					</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className="card card-custom gutter-b p-10">
                    { !isLoading ?
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>CNPJ</th>
                                <th className='min-w-140px'>Razão Social</th>
                                <th className='min-w-140px'>Cupom Utilizado</th>
                                <th className='min-w-140px'>Valor Utilizado</th>
                                <th className='min-w-120px'>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item:any) => (
                                <tr key={item.IDLojista} className={item.is_actived ? '' : 'inactive-item'}>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.CNPJ}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.RazaoSocial}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.FlgCupomUtilizado}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {brlFormat(item.ValorUtilizadoCupom)}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.is_actived ? 'Participante' : 'Não Participante'}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> :
                    <div className='justify-content-center text-center mb-4'>
                        <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                        <div className="preloader"></div>
                    </div> }
                </div>
            </div>
        </Style>
    )
}

export default StoreListModal;