import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import * as API from '../../api';
import styled from '@emotion/styled';
import WorksheetIcon from './../../components/WorksheetIcon';
import PreloaderIcon from './../../components/PreloaderIcon';

const Style = styled.div`

  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }

    .invalid-item {
        background-color: rgba(255, 0, 0, 0.1);
    }

    .wrong-text {
        background: darksalmon;
    }

    .color-wrong {
        color: crimson;
    }

    .color-correct {
        color: green;
    }
`;

type Props = {
    onClose: Function,
	promise: any,
    error?: Boolean
}

const UploadStoreModal: FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [addLoading, setAddLoading] = useState(false);
    const [url, setURL] = useState(null);
    const [error, setError] = useState(false);
    const [resultsData, setResultsData] = useState<any>([]);
    const [hasErrors, setHasErrors] = useState(false);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [willUpdateCount, setWillUpdateCount] = useState(0);

    const errors:any = {
        'exists':'Já Cadastrado',
        'not-found':'CNPJ não reconhecido',
        'name':'CNPJ não reconhecido'
    }
    
    useEffect(() => {
        setError(false);
        setURL(null);
        setLoading(true);
        if (props.promise) {
            props.promise.then((result: any) => {
                setLoading(false);
                setResultsData(result.data);
                setIncorrectCount(result.data.filter((item:any) => Array.isArray(item.errors) && item.errors.length != 0).length);
                setWillUpdateCount(result.data.filter((item:any) => item.hasChanges).length);
            }).catch((err: any) => {
                setError(true);
                setLoading(false);
            });
        }
    }, [props.promise]);

    const addItems = async() => {
        setAddLoading(true);
        const response = await API.updateStores(resultsData);
        props.onClose(true);
    }

    useEffect(() => {
        if (!resultsData || !resultsData.length) {
            setHasErrors(false);
            return;
        }
        for (let a = 0; a < resultsData.length; a++) {
            if (resultsData[a].errors.length) {
                setHasErrors(true);
                break;
            }
        }
    }, [resultsData]);

    return (
        <Style>
            <div className='modal-container text-center justify-content-center'>
                
                <div className='px-7 py-5 d-flex text-center justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>Validação e cadastro de Lojas</div>
					<div>
						{ loading && (!props.error && !error) || addLoading ? null : <BtnClose onClick={() => props.onClose()} /> }
					</div>
                    
                </div>
                
                <div className='separator border-gray-200'></div>
                { loading ? 
                <div className='px-7 py-5  text-center justify-content-center d-flex mt-10 mb-10'>
                    { loading && (!props.error && !error) ? 'Aguarde, fazendo validações no servidor' : props.error || error ? 'Houve um problema com seu arquivo' : '' }
                </div> : null }
                { addLoading ? 
                <div className='px-7 py-5  text-center justify-content-center d-flex mt-10 mb-10'>
                    Aguarde, cadastrando lista
                </div> : null }
				{ loading && (!props.error && !error) || addLoading? <div className='mb-10'>
					<PreloaderIcon/>
				</div> : props.error || error ? 'Houve um problema com seu arquivo' : 
                <div className="card card-custom gutter-b pe-10 ps-10">
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                        <tr className='fw-bold text-muted'>
                            <th className='min-w-100px'>ID Lojista</th>
                            <th className='min-w-150px'>CNPJ</th>
                            <th className='min-w-140px'>Razão Social</th>
                            <th className='min-w-120px'>Status</th>
                            <th className='min-w-100px text-center'>Problemas Encontrados</th>
                        </tr>
                    </thead>
                    <tbody>
                    { resultsData.map((item:any) => { return (
                        <>
                        { item.errors.length ? 
                            <tr key={item.IDLista} className={item.errors.length ? 'invalid-item' : ''}>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold fs-6'>
                                        {item.IDLojista}
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold d-block fs-6'>
                                        {item.CNPJ}
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold d-block fs-6'>
                                        {item.RazaoSocial}
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold d-block fs-6'>
                                        {item.is_actived ? 'Participante' : 'Não Participante'}
                                    </span>
                                </td>
                                <td className="text-center justify-content-center">
                                    <div className='justify-content-center flex-shrink-0'>
                                    { item.errors.length ? item.errors.map((i: any) => errors[i]).join('\n').split('\n').map((line:any, index:any) => (
                                        <div key={index}>
                                            {line}
                                            <br />
                                        </div>)): null }
                                    </div>
                                </td>
                            </tr> : null }
                        </>
                        
                    )})}
                    { resultsData.map((item:any) => { return (
                        <>
                        
                            { item.hasChanges ? 
                            <tr key={item.IDLista} className={''}>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold fs-6'>
                                        {item.IDLojista}
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold d-block fs-6'>
                                        {item.CNPJ}
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold d-block fs-6'>
                                        {item.RazaoSocial}
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className='text-dark fw-semibold d-block fs-6'>
                                        {item.is_actived ? 'Alterado para Participante' : 'Alterado para Não Participante'}
                                    </span>
                                </td>
                                <td className="text-center justify-content-center">
                                    <span className='text-dark fw-semibold d-block fs-6'>
                                        -
                                    </span>
                                </td>
                            </tr> : null }
                        </>
                        
                    )})}
                    </tbody>
                </table>
                { hasErrors ? <div>
                    { resultsData.length == incorrectCount ? 
                    <h4 className='mt-4 color-wrong'>Nenhum registro será atualizado</h4> : <div>
                    <h4 className='mt-4 color-wrong'>Quantidade de registros que não serão atualizados: {incorrectCount}</h4>                    
                    <h4 className='mt-4 color-correct'>Quantidade de registros que serão atualizados: {willUpdateCount}</h4>
                    </div>
                    }
                </div>    
                 : <div>
                    <h4 className='mt-4 color-correct'>{`Todos os ${resultsData.length} registros estão corretos.`}</h4>
                    <h4 className='mt-4 mb-5'>Clique no botão abaixo para atualizá-los.</h4>
                    </div>
                }
                { resultsData.length != incorrectCount ?
                <div className='mb-10 text-center justify-content-center d-flex'>
                    <a href='#' className={`btn btn-primary btn-lg btn-light mt-4`} onClick={() => { addItems()}}>
                        Atualizar
                    </a>
				</div> : <div className='mb-5'></div> }
                </div>
            }	
            </div>
        </Style>
    )
}

export default UploadStoreModal;