import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as API from '../../api';
import PermissionsModal from '../generalmenu/PermissionsModal';
import {Modal} from 'react-bootstrap';

const StyledData = styled.div`
    .remove-user-title {
        margin:20px !important;
    }

    .table {
        margin:20px;
    }

    .card:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
        
    }

    .first-col {
        padding-left: 15px !important;
    }
`;

type Props = {
    categoryId:string
  }

const CompanyPermissions:FC<Props> = ({categoryId}) => {
    const [data, setData] = useState<any>(null);
    const [products, setProducts] = useState<any[]>([]);
    const [companys, setCompanys] = useState<any[]>([]);
    const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    useEffect(() => {
        update();
    }, []);

    async function update() {
        const response = await API.getCategoryPermissions(categoryId);
        setData(response.data);
        setCompanys(response.data.company);
    }

    async function productClick(id_product:number, id_company:number, checked:boolean) {
        if (checked) {
            await API.addProductCompanyPermission({id_product, id_company});
        } else {
            await API.removeProductCompanyPermission({id_product, id_company});
        }
    }

    async function subProductClick(id_subproduct:number, id_company:number, checked:boolean) {
        if (checked) {
            await API.addSubProductCompanyPermission({id_subproduct, id_company});
        } else {
            await API.removeSubProductCompanyPermission({id_subproduct, id_company});
        }
    }

    async function onPermissionModalRequest(selectAll:boolean) {
        if (selectedItem.id_subproduct) {
            selectAll ? await API.selectAllCompanysIntoSubproduct(selectedItem.id_subproduct) : await API.unselectAllCompanysIntoSubproduct(selectedItem.id_subproduct);
        } else {
            selectAll ? await API.selectAllCompanysIntoProduct(selectedItem.id_product) : await API.unselectAllCompanysIntoProduct(selectedItem.id_product);
        }
        await update();
        setShowPermissionsModal(false);
    }

    return <StyledData>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-400px' show={showPermissionsModal}>
            <PermissionsModal item={selectedItem} onChange={(value:boolean) => { onPermissionModalRequest(value)}} onClose={() => { 
                setShowPermissionsModal(false);
            }}/>
        </Modal>
        <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle justify-content-center'>
                <thead>
                    <tr className='fw-bold text-muted justify-content-center first-col'>
                        <th className='w-200px first-col'>Empresa</th>
                        {data?.menu.products.map((product:any) => { return (
                            <React.Fragment key={`c_p_${product.id_product}`}>
                                <th key={`title_product_${product.id_product}`} className='justify-content-center text-center'>
                                    <button className='btn btn-active-light-primary' onClick={() => {
                                        setSelectedItem(product);
                                        setShowPermissionsModal(true);
                                    }}>{product.admin_name ? product.admin_name : product.name}</button>
                                </th>
                                { product.subproducts.map((subproduct:any) => { return (
                                <th key={`title_product_${subproduct.id_subproduct}`} className='justify-content-center text-center'>
                                    <button className='btn btn-active-light-primary' onClick={() => {
                                        setSelectedItem(subproduct);
                                        setShowPermissionsModal(true);
                                    }}>{subproduct.admin_name ? subproduct.admin_name : subproduct.name}</button>
                                </th>
                                )})}
                            </React.Fragment>
                        )})}
                    </tr>
                </thead>
                <tbody>
                    <>{ data?.companys?.map((company:any) => { return (
                        <tr className='justify-content-center'>
                            <td>
                                <span className='w-200px text-muted fw-semibold text-muted d-block fs-7 first-col'>{company.name}</span>
                            </td>
                            {data?.menu.products.map((product:any) => { return (
                                <React.Fragment key={`cc_p_${product.id_product}`}>
                                    <td key={Math.random()} className="justify-content-center align-middle">
                                        <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                                            <input className='form-check-input widget-9-check ' type='checkbox' defaultChecked={data.products.find((p:any) => { return product.id_product == p.id_product && p.id_company == company.id_company })} onChange={(evt) => { productClick(product.id_product, company.id_company, evt.target.checked) }}/>
                                        </div>
                                    </td>
                                    { product.subproducts.map((subproduct:any) => { return (
                                        <td key={Math.random()} className="justify-content-center align-middle">
                                            <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                                                <input className='d-flex form-check-input widget-9-check' type='checkbox' defaultChecked={data.subProducts.find((sp:any) => { return subproduct.id_subproduct == sp.id_subproduct && sp.id_company == company.id_company })} onChange={(evt) => { subProductClick(subproduct.id_subproduct, company.id_company, evt.target.checked) }} />
                                            </div>
                                        </td>
                                    )})}
                                </React.Fragment>
                            )})}
                        </tr>
                    )})}</>
                </tbody>
            </table>
        </div>
    </StyledData>

}

export default CompanyPermissions;