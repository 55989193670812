import React from 'react';

type Column = {
  name: string;
  position: number;
};

type Row = {
  values: Array<{ str: string }>;
};

type TableProps = {
  columns: Column[];
  rows: Row[];
};

const TableDisplay: React.FC<TableProps> = ({ columns, rows }) => {
  if (!columns || !rows) return null;

  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column) => (
                <th
                  key={`${column.position}-${Math.random()}`}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rows.map((row, rowIndex) => (
              <tr
                key={`row-${rowIndex}-${Math.random()}`}
                className="hover:bg-gray-50 transition-colors duration-150"
              >
                {row.values.map((value, valueIndex) => (
                  <td
                    key={`cell-${rowIndex}-${valueIndex}-${Math.random()}`}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                  >
                    {value.str}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDisplay;