import React from 'react';
import { Bot } from 'lucide-react';
import { Chat } from './Chat';

type Props = {
  className?: string;
};

const AIScreen: React.FC<Props> = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w mx-auto">
        <div className="bg-white rounded-2xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center">
            <div className="p-2 bg-white/10 rounded-lg">
              <Bot className="w-6 h-6 text-white" />
            </div>
            <div className="ml-4">
              <h1 className="text-lg font-semibold text-white">
                Compra Agora - Inteligência Artificial
              </h1>
              <p className="text-sm text-white/80">
                Assistente virtual para suporte e consultas
              </p>
            </div>
          </div>
          <Chat />
        </div>
      </div>
    </div>
  );
};

export default AIScreen;