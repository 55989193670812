import { Lock, Unlock } from 'lucide-react';

type Permission = {
  id_feature: string;
  title: string;
  name: string;
};

type PermissionListProps = {
  permissions: Permission[];
  selectedPermissions: string[];
  onPermissionChange: (permission: Permission, checked: boolean) => void;
};

export const PermissionList = ({
  permissions,
  selectedPermissions,
  onPermissionChange,
}: PermissionListProps) => {
  // Group permissions by category (based on the first part of the name)
  const groupedPermissions = permissions.reduce((acc, permission) => {
    const category = permission.name.split('-')[0];
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(permission);
    return acc;
  }, {} as Record<string, Permission[]>);

  return (
    <div className="space-y-6">
      {Object.entries(groupedPermissions).map(([category, perms]) => (
        <div key={category} className="bg-white rounded-xl border-2 border-gray-100 overflow-hidden">
          <div className="bg-gray-50 px-6 py-3 border-b-2 border-gray-100">
            <h3 className="text-lg font-semibold text-gray-800 capitalize">{category}</h3>
          </div>
          <div className="divide-y divide-gray-100">
            {perms.map((permission) => (
              <label
                key={permission.id_feature}
                className="flex items-center px-6 py-4 hover:bg-blue-50 transition-colors cursor-pointer group"
              >
                <div className="relative flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedPermissions.includes(permission.id_feature)}
                    onChange={(e) => onPermissionChange(permission, e.target.checked)}
                    className="w-5 h-5 rounded border-gray-300 text-blue-600 focus:ring-blue-200"
                  />
                  <div className="ml-3 flex-1">
                    <span className="text-gray-700 font-medium group-hover:text-blue-600 transition-colors">
                      {permission.title}
                    </span>
                  </div>
                  {selectedPermissions.includes(permission.id_feature) ? (
                    <Unlock className="w-4 h-4 text-blue-600 ml-2" />
                  ) : (
                    <Lock className="w-4 h-4 text-gray-400 ml-2 group-hover:text-blue-600" />
                  )}
                </div>
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};