/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect } from 'react';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { AddMenuItemModal } from '../../../partials/modals/add-menu-item/AddMenuItemModal';
import * as API from '../../../../app/api';
import styled from '@emotion/styled';
import { isSuperAdmin, getUserData, GetPermissions } from '../../../../app/modules/auth';

const StyledMenu = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 12px;
	height: 100%;

	.btn-add-container {
		padding: 16px;
	}

	.text-center {
		color:#d8d8d8;
		text-align:center;
	}

	.menu-item-border {
		border-bottom: 1px solid #ccc!important;
	}

	p {
		color:#d8d8d8;
		text-align:center;
	}
`

export function AsideMenuMain() {
	const permissions = GetPermissions();
	
	const [menu, setMenu] = useState<any[]|undefined>(undefined);
	
	async function fetchMenu(force:boolean = false) {
		if (!force && menu && menu.length) return;
		const response = await API.getMenu();
		setMenu(response.data);
	}	

	function renderMenu(item:any) {

		if (!item) return null;

		if (item.section) {
			return <>
			<div className='menu-item'>
				<div className='menu-content pt-8 pb-2 text-center'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>{ item.section }</span>
				</div>
			</div>
			{ item.children && item.children.map((x:any, i:number) => renderMenu(x) )}
			</>
		}

		const MenuElement = item.children && item.children.length > 0 ? AsideMenuItemWithSub : AsideMenuItem;

		if (item.label) {
			return <MenuElement
			key={item.id}
			to={item.to}
			title={item.label}
			fontIcon={item.fontIcon}
			icon={item.icon}
			hasBullet={item.hasBullet}
			>
				{ item.children && item.children.map((x:any, i:number) => renderMenu(x)) }
			</MenuElement>
		}
	}

	return <StyledMenu>
		<div className='pt-4'>
			{ permissions?.some(feature => feature.name.includes('cashback-') && feature.name.includes('view')) ? 
			<AsideMenuItemWithSub to='cashback' icon='/media/icons/duotune/menu/cashback.svg' title={'Cashback'} fontIcon='bi-app-indicator'>
				{ permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-view')) ? 
				<AsideMenuItem to='/lojas' icon='/media/icons/duotune/menu/store.svg' title={'Lojas Participantes'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-listas-view')) ? 
				<AsideMenuItem to='/listas' icon='/media/icons/duotune/menu/lists2.svg' title={'Listas'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-regionais-view')) ? 
				<AsideMenuItem to='/regionais' icon='/media/icons/duotune/menu/icon-regional.svg' title={'Regionais'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-tamanhos-view')) ? 
				<AsideMenuItem to='/tamanhos' icon='/media/icons/duotune/menu/ruler.svg' title={'Tamanho'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-bus-view')) ? 
				<AsideMenuItem to='/bus' icon='/media/icons/duotune/menu/building.svg' title={'BU'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-tabela-valores-view')) ? 
				<AsideMenuItem to='/precos' icon='/media/icons/duotune/menu/document-sheet.svg' title={'Tabela de Valores'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-cupons-view')) ? 
				<AsideMenuItem to='/cupons' icon='/media/icons/duotune/menu/coupon2.svg' title={'Cupons'} fontIcon='bi-app-indicator'/> : null }
				
				{ permissions?.some(feature => feature.name.includes('cashback-relatorios')) ? 
				<AsideMenuItemWithSub to='/relatorios' icon='/media/icons/duotune/menu/document.svg' title={'Relatórios'} fontIcon='bi-app-indicator'>
					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-positivacao')) ? 
					<AsideMenuItem to='/relatorios/positivacao' icon='/media/icons/duotune/menu/icon-positivacao.svg' title={'Positivação'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-ativacao')) ? 
					<AsideMenuItem to='/relatorios/ativacao' icon='/media/icons/duotune/menu/icon-ativacao.svg' title={'Ativação'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-lojistas')) ? 
					<AsideMenuItem to='/relatorios/lojistas' icon='/media/icons/duotune/menu/store.svg' title={'Lojistas'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-cupons')) ? 
					<AsideMenuItem to='/relatorios/cupons' icon='/media/icons/duotune/menu/coupon2.svg' title={'Cupons'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-apuracao')) ? 
					<AsideMenuItem to='/relatorios/apuracao' icon='/media/icons/duotune/menu/apuracao3.svg' title={'Apuração'} fontIcon='bi-app-indicator'/> : null }
				</AsideMenuItemWithSub>: null }
				{ permissions?.some(feature => feature.name === 'cashback-alert-view') ? 
				<AsideMenuItem to='/alerts' icon='/media/icons/duotune/menu/icon-alerts.svg' title={'Alertas'} fontIcon='bi-app-indicator'/> : null }
			</AsideMenuItemWithSub>	: null }
			{ permissions?.some(feature => feature.name === 'logs-view') ? 
				<AsideMenuItem to='/logs' icon='/media/icons/duotune/menu/icon-logs.svg' title={'Logs'} fontIcon='bi-app-indicator'/>: null }
			{ permissions?.some(feature => feature.name === 'ai-view') ? <AsideMenuItem to='/ai' icon='/media/icons/duotune/menu/robot-ai-svgrepo-com.svg' title={'Compra AI'} fontIcon='bi-app-indicator'/> : null }
			{ isSuperAdmin() ? <AsideMenuItem to='/permissions' icon='/media/icons/duotune/menu/permissions2.svg' title={'Permissões'} fontIcon='bi-app-indicator'/> : null }
		</div>
		
		{ !!menu && menu.map((item, i) => <div key={'section' + i}>{renderMenu(item)}</div>) }
		<div className="space"></div>
		{ menu && !menu.length && <p className='mt-4'>Menu indisponível</p> }
	</StyledMenu>
}