const BtnPermissions = () => (
    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" >
<polygon fill="currentColor" points="294.884,265.574 0.073,511.855 0.206,445.392 230.678,214.921 "/>
<path fill="currentColor" d="M468.441,43.486c-57.97-57.982-151.973-57.982-209.955,0
	c-46.383,46.383-55.652,115.83-27.808,171.434c6.94,13.892,16.221,26.934,27.808,38.521L0.073,511.855l128.873,0.146v-62.677h62.689
	v-62.689h62.677v-62.677l42.695-42.707c55.616,27.844,125.051,18.575,171.434-27.808C526.423,195.46,526.423,101.468,468.441,43.486
	z M427.974,147.443c-17.53,17.53-45.954,17.53-63.485,0c-17.53-17.53-17.53-45.954,0-63.485c17.53-17.53,45.955-17.53,63.485,0
	C445.505,101.489,445.505,129.912,427.974,147.443z"></path>
</svg>
);

export default BtnPermissions;