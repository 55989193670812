import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import Select from 'react-select';
import styled from '@emotion/styled';
import * as API from '../../api';

const Style = styled.div`
  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select {
    width: 100%;
    margin-top: 1rem;
  }

  .mailling-item {
    margin-right: 30px;

    label {
      margin-right: 10px;
    }
  }
`;

type Props = {
  onChange: Function;
  onClose: Function;
  user: any;
  permissions: any
};

const UserPermissionModal: FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const [permissions, setPermissions] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!props.permissions) return;
    setPermissions(props.permissions);
  }, [props.permissions]);

  useEffect(() => {
    if (!props.user) return;
    setSelectedPermissions(props.user.permissions);
  }, [props.user]);

  const handlePermissionChange = (permission: any, isChecked: boolean) => {
    setSelectedPermissions((prevSelected) => {
        if (!prevSelected) prevSelected = [];
      if (isChecked) {
        return [...prevSelected, permission.id_feature_group];
      }
      return prevSelected.filter((p) => p !== permission.id_feature_group);
    });
  };

  const getData = () => {
    const obj:any = {
        id_user:props.user.id_user,
        data:selectedPermissions
    };
    //if (props.group) obj.id_feature_group = props.group.id_feature_group;
    return obj;
  }
  const updatePermissions = async () => {
    setLoading(true);
    const response = await API.addUserPermissions(getData());
    props.onChange();
  };

  return (
    <Style>
      <div className='modal-container'>
        <div className='px-7 py-5 d-flex justify-content-between'>
          <div className='pt-2 fs-3 text-dark fw-bolder'>Permissões</div>
          <BtnClose onClick={() => props.onClose()} />
        </div>

        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-4 d-flex flex-wrap'>
            <div className='justify-content-between modal-container'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <tbody>
                  {permissions.map((permission: any, index: number) => {
                    
                      return (
                        <tr key={permission.id_feature_group}>
                          <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid w-5px'>
                              <input
                                className='form-check-input widget-9-check'
                                type='checkbox'
                                value='1'
                                checked={selectedPermissions?.includes(
                                  permission.id_feature_group
                                )}
                                onChange={(e) =>
                                  handlePermissionChange(
                                    permission,
                                    e.target.checked
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <span className='text-dark fw-bold fs-6'>
                                {permission.name}
                              </span>
                            </div>
                          </td>
                          
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <button
                onClick={() => {
                  updatePermissions();
                }}
                className='btn btn-primary filter-btn'
              >
                {!loading && `${'Confirmar Permissões'}`}{' '}
                {loading && (
                  <span
                    className='indicator-progress'
                    style={{ display: 'block' }}
                  >
                    Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default UserPermissionModal;