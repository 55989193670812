import styled from "@emotion/styled";
import { Modal } from 'react-bootstrap';
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { PageTitle } from "../../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from 'react';
import * as API from '../../../api';
import ConfirmModal from '../../modals/ConfirmModal';
import BtnEdit from "../../../components/BtnEdit";

const ScreenStyle = styled.div`
    position: relative;
    
    .new-rules-button {
        right: 0px;
    }
    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }

    .preloader {
        position: relative;
        display: inline-table;
        width: 32px;
        height: 32px;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>')
    }
    
    .action-btn {
        transition: opacity 0.1s ease;
    }

    .action-btn:hover {
        opacity: 0.6;
    }

    .inactive-item {
        background-color: rgba(255, 0, 0, 0.1);
    }
`;

type Props = {
    className?: string
  }

const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    return new Date(dateString).toLocaleString('pt-BR', options);
}

const RegionalScreen:React.FC<Props> = ({className}) => {
    const [message, setMessage] = useState<string>('');
    const [confirmModal, showConfirmModal] = useState(false);
    const [selectedList, setSelectedList] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        showConfirmModal(message != '');
    }, [message]);

    const getData = async() => {
        setIsLoading(true);
        const response = await API.getRegionalList();
        setData(response.data);
        setIsLoading(false);
        console.log(response);
    }

    return (
        <ScreenStyle>
            <PageTitle>Lista de Regionais</PageTitle>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-600px' show={confirmModal}>
                <ConfirmModal title={`${selectedList?.NomeLista}`} onClose={(value:any) => {
                    
                    //setSelectedList(null);
                    setMessage('');
                }} message={message}/>
            </Modal>
            { /* <div className='d-flex py-1 justify-content-end'>
                <div className=''>
                    <a href='#' className='btn btn-primary disabled btn-sm btn-light d-flex' onClick={() => {}}>
                        Cadastrar Nova Regional
                    </a>
                </div>
            </div> */}
            <div className={`card mt-4`}>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        { !isLoading ? 
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-200px text-center'>Nome</th>
                                    { /* <th className='min-w-100px text-center'>Ações</th> */ }
                                </tr>
                            </thead>
                            <tbody>
                            { data.map((item:any) => { return (
                                <tr key={item.IDRegional}>
                                    
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.NomeRegional}
                                        </span>
                                    </td>
                                    { /* <td className="text-center">
                                        <div className='d-flex justify-content-center flex-shrink-0'>
                                            <a href='#' className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 action-btn disabled`} onClick={() => {}}>
                                                <BtnEdit/>
                                            </a>
                                        </div>
                                    </td> */ }
                                </tr>
                            )})}
                            </tbody>
                        </table> :
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                            <div className="preloader"></div>
                        </div> }
                    </div>
                </div>
            </div>
        </ScreenStyle>
    )
}

export default RegionalScreen;