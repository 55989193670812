import { FC, useState, useEffect } from 'react';
import { X, Shield } from 'lucide-react';
import * as API from '../../api';
import { SearchInput } from './SearchInput';
import { GroupNameInput } from './GroupNameInput';
import { PermissionList } from './PermissionList';
import { ActionButton } from './ActionButton';

type Props = {
  onChange: Function;
  onClose: Function;
  data: any;
  group?: any;
};

const PermissionModal: FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [permissions, setPermissions] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (!props.data) return;
    setPermissions(props.data);
  }, [props.data]);

  useEffect(() => {
    if (!props.group) return;
    setSelectedPermissions(props.group.data);
    setName(props.group.name);
  }, [props.group]);

  const handlePermissionChange = (permission: any, isChecked: boolean) => {
    setSelectedPermissions((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, permission.id_feature];
      }
      return prevSelected.filter((p) => p !== permission.id_feature);
    });
  };

  const getData = () => {
    const obj: any = {
      name,
      data: selectedPermissions,
    };
    if (props.group) obj.id_feature_group = props.group.id_feature_group;
    return obj;
  };

  const handleSubmit = async () => {
    if (!name.length) return;
    setLoading(true);
    try {
      if (props.group) {
        await API.updateGroupPermissions(getData());
      } else {
        await API.addGroupPermissions(getData());
      }
      props.onChange();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredPermissions = permissions.filter((permission) =>
    permission.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden animate-scale-up">
        {/* Header */}
        <div className="px-8 py-6 border-b border-gray-200 flex items-center justify-between bg-gradient-to-r from-blue-600 to-blue-700">
          <div className="flex items-center space-x-4">
            <div className="p-2 bg-white/10 rounded-xl">
              <Shield className="w-6 h-6 text-white" />
            </div>
            <h2 className="text-2xl font-bold text-white">Gerenciar Permissões</h2>
          </div>
          <button
            onClick={() => props.onClose()}
            className="text-white/80 hover:text-white hover:bg-white/10 rounded-lg p-2 transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="flex flex-col h-[calc(90vh-5rem)]">
          <div className="p-8 space-y-6">
            <GroupNameInput value={name} onChange={setName} />
            <SearchInput value={searchTerm} onChange={setSearchTerm} />
          </div>

          {/* Scrollable Permission List */}
          <div className="flex-1 px-8 overflow-y-auto custom-scrollbar">
            <PermissionList
              permissions={filteredPermissions}
              selectedPermissions={selectedPermissions}
              onPermissionChange={handlePermissionChange}
            />
          </div>

          {/* Footer */}
          <div className="p-8 border-t border-gray-200 bg-gray-50">
            <ActionButton
              loading={loading}
              disabled={loading || !name.length}
              isEdit={!!props.group}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>

      <style>{`
        .animate-scale-up {
          animation: scaleUp 0.2s ease-out;
        }

        @keyframes scaleUp {
          from {
            transform: scale(0.95);
            opacity: 0;
          }
          to {
            transform: scale(1);
            opacity: 1;
          }
        }

        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #CBD5E1 transparent;
        }

        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #CBD5E1;
          border-radius: 3px;
        }
      `}</style>
    </div>
  );
};

export default PermissionModal;