import styled from "@emotion/styled";
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { PageTitle } from "../../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import * as API from '../../../api';
import PreloaderIcon from '../../../components/PreloaderIcon';
import WorksheetIcon from '../../../components/WorksheetIcon';
import ReportCurrent from './LojistasReportCurrent';
import ReportMonth from './LojistasReportMonth';

const ScreenStyle = styled.div`
    position: relative;
    
    .new-rules-button {
        right: 0px;
    }
    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }

    .preloader {
        position: relative;
        display: inline-table;
        width: 32px;
        height: 32px;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>')
    }

    .nav-item {
        cursor:pointer;
      }
`;

type Props = {
    className?: string
  }

const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    return new Date(dateString).toLocaleString('pt-BR', options);
}

const ReportLojistas:React.FC<Props> = ({className}) => {
    const [activeTab, setActiveTab] = useState('current');

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>([]);    

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        
    }, []);
    
    return (
        <ScreenStyle>
            <PageTitle>Relatório de Lojistas</PageTitle>
            <div className={`card`}>
                
                    
                    <div className="card-body bg-gray-50">
                        <ReportCurrent/>
                    </div>
                
            </div>
        </ScreenStyle>
    )
}

export default ReportLojistas;