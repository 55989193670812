import styled from "@emotion/styled";
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from "../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from 'react';
import * as API from '../../api';
import DetailsModal from '../modals/AlertDetailsModal';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import Select from 'react-select';
import IconMagnifying from "../../components/IconMagnifying";

const ScreenStyle = styled.div`
	position: relative;

	.new-rules-button {
		right: 0px;
	}
	
	.search-icon {
		position: relative;
		padding-top: 8px;
		right: 35px;
	}

	.inactive-item {
		background-color: rgba(255, 0, 0, 0.1);
	}

	.action-btn {
		transition: opacity 0.1s ease;
	}

	.action-btn:hover {
		opacity: 0.6;
	}

	.filter-select {
		height: 42px;
		display: flex;
		justify-content: flex-end;
	}
`;

type Props = {
  	className?: string;
};

const formatDate = (dateString: string): string => {
    const date = new Date(dateString); 
    const isoString = date.toISOString();
    const year = isoString.substring(0, 4);
    const month = isoString.substring(5, 7);
    const day = isoString.substring(8, 10);
    const hour = isoString.substring(11, 13);
    const minute = isoString.substring(14, 16);
    const second = isoString.substring(17, 19);  
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
};

const AlertScreen: React.FC<Props> = ({ className }) => {
	const [uploadModal, showUploadModal] = useState(false);
	const [uploadPromise, setUploadPromise] = useState<any>(null);
	const [storeModal, showStoreModal] = useState(false);
	const [selectedVoucher, setSelectedVoucher] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [name, setName] = useState<string>("");
	const [data, setData] = useState<any>(null);
	const [users, setUsers] = useState<any>([]);
	const [types, setTypes] = useState<any>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [searchData, setSearchData] = useState<any>([]);
	const [resultsData, setResultsData] = useState<any>([]);
	const [user, setUser] = useState<any>(null);
	const [type, setType] = useState<any>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [detailsModal, showDetailsModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);

	useEffect(() => {
		loadUsers();
		loadTypes();
		loadData();
	}, []);

	const loadUsers = async () => {
		const response = await API.listUsers();
		setUsers(response.data);
		setIsLoading(false);
	};

	const loadTypes = async() => {
		const typesResponse = await API.listAlertsTypes();
		
		setTypes(typesResponse.data);
	}

	const loadData = async () => {
		const response = await API.listAlerts(user?.value, type?.value);
		setData(response.data);
		setIsLoading(false);
	};

	useEffect(() => {
		loadData();
	}, [user, type]);

	useEffect(() => {
		showDetailsModal(selectedItem != null);
	}, [selectedItem]);

	return (
		<ScreenStyle>
		{isSuperAdmin() ? (
			<>
			<PageTitle>Alertas</PageTitle>
			<Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-1200px' show={detailsModal} onHide={() => setSelectedItem(null)}>
				<DetailsModal data={selectedItem} onClose={() => setSelectedItem(null)} />
			</Modal>

			{ /* <div className="card card-custom gutter-b p-10">
				<div className="d-flex monitor-alert-box">
					<div className="form-item w-100">
						<div className="d-flex w-100">
							<Select 
								className="voucher-filter w-250px form-select-solid form-item" 
								value={user} 
								placeholder={'Filtro por usuário'}
								options={users.map((u: any) => ({ value: u.id_user, label: u.name }))}
								onChange={(e) => {
								setUser(e);
								}}
							/>
							<Select 
								className="voucher-filter w-250px form-select-solid form-item ms-5" 
								value={type} 
								placeholder={'Filtro por tipo'}
								options={types.map((t: any) => ({ value: t.id_log_type, label: t.name }))}
								onChange={(e) => {
								setType(e);
								}}
							/>
						</div>
					</div>
				</div>
			</div> */ }
			
			<div className={`card mt-4`}>
				<div className="card-body py-3">
					<div className="table-responsive">
						{!isLoading ? data?.length ? (
						<table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
							<thead>
							<tr className="fw-bold text-muted">
								<th className="min-w-50px text-center">Usuário</th>
								<th className="min-w-200px text-center">Descrição</th>
								{ /* <th className="min-w-100px text-center">Tipo</th> */ }
								<th className="min-w-100px text-center">Data</th>
								<th className="min-w-100px text-center">Registros</th>
								<th className="min-w-100px text-center">Detalhes</th>
							</tr>
							</thead>
							<tbody>
							{ data.map((item: any, index: any) => {
								return (
								<tr key={`${item.id_alert}`} className={''}>
									<td className="text-center">
										<span className="text-dark fw-semibold fs-6">{item.user ? item.user : '-'}</span>
									</td>
									<td className="text-center">
										<span className="text-dark fw-semibold d-block fs-6">
											{item.name}
										</span>
									</td>
									{/* <td className="text-center">
										<span className="text-dark fw-semibold d-block fs-6">
											{item.type}
										</span>
									</td> */ }
									<td className="text-center">
										<span className="text-dark fw-semibold d-block fs-6">
											{formatDate(item.created_at)}
										</span>
									</td>
									<td className="text-center">
										<span className="text-dark fw-semibold d-block fs-6">
											{item.length}
										</span>
									</td>
									<td className="text-center">
										<a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 action-btn' onClick={() => setSelectedItem(item)}>
											<IconMagnifying className={'svg-icon-3'}></IconMagnifying>
										</a>
									</td>
								</tr>
								);
							})}
							</tbody>
						</table>
						) : <>
						<span className="text-dark fw-semibold d-block fs-6 justify-content-center text-center">
							Nenhum alerta encontrado.	
						</span>
						</> : (
						<div className="justify-content-center text-center mb-4">
							<p className="mb-5 mt-5">Carregando, aguarde um momento</p>
							<div className="preloader"></div>
						</div>
						)}
					</div>
				</div>
			</div>
			</>
		) : null}
		</ScreenStyle>
	);
};

export default AlertScreen;