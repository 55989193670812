import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as API from '../../api';

const StyledData = styled.div`
    .table {
        margin: 20px;
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
    }

    .first-col {
        padding-left: 15px !important;
    }

    /* Custom styles for filters */
    .filter-group {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .filter-radio {
        margin-right: 10px;
    }

    .filter-dropdown {
        position: relative;
    }

    .filter-dropdown-input {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 150px;
        margin-right: 5px;
    }

    .filter-dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: 200px;
        overflow-y: auto;
        display: none;
    }

    .filter-dropdown-option {
        padding: 5px 10px;
        cursor: pointer;
    }

    .filter-dropdown-option:hover {
        background-color: #f6f6f6;
    }

    .filter-dropdown:hover .filter-dropdown-options {
        display: block;
    }

    .filter-dropdown-selected {
        color: #333;
    }
`;

const FeatureMatrix: FC = () => {
    const [menu, setMenu] = useState<any>();
    const [filterMenu, setFilterMenu] = useState<any>();
    const [selectedFilter, setSelectedFilter] = useState('Todos');
    const [dropdownValue, setDropdownValue] = useState('');
    const [selectedDropdownValues, setSelectedDropdownValues] = useState<any[]>([]);
    const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);

    const getFeatures = async () => {
        const result: any = await API.getFeatures();
        setMenu(result.data);
        setFilterMenu(result.data);
        setFilteredCompanies(result.data.companys);
        console.log(result.data);
    };

    async function featurePermissionClick(id_feature: number, id_company: number, checked: boolean) {
        if (checked) {
            await API.addFeatureCompanyPermission({ id_feature, id_company });
            menu.permissions.push({guid:Math.random(), id_company, id_feature});
        } else {
            await API.removeFeatureCompanyPermission({ id_feature, id_company });
            menu.permissions.forEach((permission:any, index:number) => {
                if (permission.id_feature === id_feature && permission.id_company) {
                    menu.permissions.splice(index, 1);
                    return;
                }
            });
        }
    }

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFilter(event.target.value);
    };

    const handleDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDropdownValue(event.target.value);
        const filtered = menu?.companys?.filter((company:any) =>
            company.name.toLowerCase().includes(dropdownValue.toLowerCase())
        );
        if (dropdownValue !== '') {
            setFilterMenu({companys:filtered});
        }
    };

    const handleDropdownSelect = (option: string) => {
        if (selectedDropdownValues.includes(option)) {
            setSelectedDropdownValues(selectedDropdownValues.filter((value) => value !== option));
        } else {
            setSelectedDropdownValues([...selectedDropdownValues, option]);
        }
    };

    useEffect(() => {
        getFeatures();
    }, []);

    useEffect(() => {
        const filteredData = menu?.companys?.filter((company:any) => {
            const filterMatch = selectedFilter === 'Todos' || company.id_type_company == selectedFilter;
            const dropdownMatch = selectedDropdownValues.length === 0 || selectedDropdownValues.includes(company.name);
            return filterMatch && dropdownMatch;
        });
        setFilteredCompanies(filteredData);
    }, [selectedFilter, dropdownValue, selectedDropdownValues, menu]);

    return (
    <StyledData>
        <div key={`features`} className="">
            <div className="table-responsive">
                <div className='w-600px'>                    
                    <div className="filter-group d-flex justify-content-between">
                        <label>Tipo de Empresa:</label>
                        <label htmlFor="filter-radio-todos" className='me-5'>
                            <input type="radio" id="filter-radio-todos" name="filter-radio" value="Todos" checked={selectedFilter === 'Todos'} onChange={handleFilterChange} className="filter-radio"/>
                            Todos
                        </label>
                        <label htmlFor="filter-radio-industrias" className='me-5'>
                            <input type="radio" id="filter-radio-industrias" name="filter-radio" value="1" checked={selectedFilter === '1'} onChange={handleFilterChange} className="filter-radio"/>
                            Indústrias
                        </label>
                        <label htmlFor="filter-radio-distribuidores" className='me-5'>
                            <input type="radio" id="filter-radio-distribuidores" name="filter-radio" value="2" checked={selectedFilter === '2'} onChange={handleFilterChange} className="filter-radio"/>
                            Distribuidores
                        </label>   
                    </div>
                </div>
                <div className="filter-group">
                    <div className="filter-dropdown">
                        <input type="text" className="filter-dropdown-input w-400px" placeholder="Buscar por empresas" value={dropdownValue} onChange={handleDropdownChange}/>
                        <div className="filter-dropdown-options">
                            {filterMenu?.companys?.map((company: any) => (
                            <div key={`filter-dropdown-${company.id_company}`} className="filter-dropdown-option" onClick={() => handleDropdownSelect(company.name)}>
                                {company.name}
                            </div>
                            ))}
                        </div>
                    </div>
                    {selectedDropdownValues.length > 0 && (
                        <span className="filter-dropdown-selected me-5 ms-5">
                            Selecionados: {selectedDropdownValues.join(', ')}
                        </span>
                    )}
                </div>
                <table className="table table-row-dashed table-row-gray-300 align-middle justify-content-center">
                    <thead>
                        <tr className="fw-bold text-muted first-col">
                            <th className="w-200px first-col">Empresa (A-Z)</th>
                            {menu?.features?.map((feature: any) => (
                            <React.Fragment key={`c_p_${feature.id_feature}`}>
                                <th key={`title_product_${feature.id_product}`} className="justify-content-center text-center">
                                    <button className="btn btn-active-light-primary" onClick={() => { /* Feature click handler */ }}>
                                        {feature.title}
                                    </button>
                                </th>
                            </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {filteredCompanies?.sort((a, b) => (a.name < b.name ? -1 : 1)).map((company: any) => (
                        <tr key={`${company.id_company}`} className="justify-content-left">
                            <td>
                                <span className="w-200px text-muted fw-semibold text-muted d-block fs-7 first-col">
                                {company.name}
                                </span>
                            </td>
                            {menu.features.map((feature: any) => (
                            <React.Fragment key={`cc_p_${feature.id_product}_${feature.id_feature}`}>
                                <td key={Math.random()} className="justify-content-center align-middle">
                                    <div className="form-check form-check-sm form-check-custom d-flex justify-content-center text-center">
                                        <input
                                            className="form-check-input widget-9-check"
                                            type="checkbox"
                                            defaultChecked={menu.permissions.find((p: any) => p.id_feature === feature.id_feature && p.id_company === company.id_company)}
                                            onChange={(evt) => featurePermissionClick(feature.id_feature, company.id_company, evt.target.checked)}
                                        />
                                    </div>
                                </td>
                            </React.Fragment>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>            
            </div>
        </div>
    </StyledData>
    );
};

export default FeatureMatrix;