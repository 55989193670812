type Column = {
    key: string;
    label: string;
  };
  
  type TableProps = {
    columns: Column[];
    data: any[];
  };
  
  const formatValue = (key: string, value: any) => {
    if (key === 'PorcentagemQueUtilizaram') {
      return `${parseInt(value)}%`;
    }
    if (key === 'ValorUtilizadoCupom') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    }
    return value;
  };
  
  export const Table = ({ columns, data }: TableProps) => (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((row, rowIndex) => (
          <tr
            key={`${row.IDRegraPromocao}_${rowIndex}`}
            className="hover:bg-gray-50 transition-colors duration-150"
          >
            {columns.map((column) => (
              <td
                key={`${rowIndex}-${column.key}`}
                className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center"
              >
                {formatValue(column.key, row[column.key])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );