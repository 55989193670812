import { FC, useState, useEffect } from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import styled from '@emotion/styled';

const Style = styled.div`
  .modal-container {
    width: 100%;
    max-height: 70vh; /** Add this to control the height */
    overflow-y: auto; /** Add this to enable scrolling inside the modal */
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }
`;

type Props = {
    onClose: Function,
    data: any
}

const LogDetailsModal: FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [url, setURL] = useState(null);
    const [error, setError] = useState(false);
    const [data, setData] = useState<any>(null);
    const limit = 2000000;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!props.data) return;
        setData(props.data);
        console.log(props.data);
    }, [props.data]);

    function formatCNPJ(cnpj:string) {
        cnpj = cnpj.replace(/\D/g, '');
        if (cnpj.length !== 14) {
            return cnpj;
        }
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }

    return (
        <Style>
            <div className='modal-container text-center justify-content-center'>
                <div className='px-7 py-5 d-flex text-center justify-content-between'>
                    { data ? <div className='pt-2 fs-3 text-dark fw-bolder'>{`${data.RazaoSocial} - ${formatCNPJ(data.CNPJLojista)}`}</div> : null }
					<div>
						<BtnClose onClick={() => props.onClose()} />
					</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className="card card-custom gutter-b p-10">
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-100px text-center'>ID Pedido</th>
                                <th className='min-w-100px text-center'>ID Promoção</th>
                                <th className='min-w-100px text-center'>Nome Promoção</th>
                                <th className='min-w-100px text-center'>BU</th>
                                <th className='min-w-100px text-center'>Tamanho</th>
                                <th className='min-w-100px text-center'>Regional</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.details.map((item:any, index:any) => (
                                <tr key={`${item.IDPedidoAtivacao}_${index}`}>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold fs-6'>
                                            {item.IDPedidoAtivacao}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.IDRegraPromocaoAtivacao}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.NomePromocao}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.BU}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.Tamanho}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.NomeRegional}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Style>
    )
}

export default LogDetailsModal;