import styled from "@emotion/styled";
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import { PageTitle } from "../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import * as API from '../../api';
import PreloaderIcon from '../../components/PreloaderIcon';

const ScreenStyle = styled.div`
    position: relative;
    
    .new-rules-button {
        right: 0px;
    }
    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }

    .preloader {
        position: relative;
        display: inline-table;
        width: 32px;
        height: 32px;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>')
    }
`;

type Props = {
    className?: string
  }

const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    return new Date(dateString).toLocaleString('pt-BR', options);
}

const InvestigationScreen:React.FC<Props> = ({className}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>(''); 

    useEffect(() => {
        loadVouchersList();
    }, []);

    const loadVouchersList = async() => {
        const response = await API.getVouchersList();
        setData(response.data);
        setIsLoading(false);
        console.log(response);
    }

    const trackEvent = (title:string):any => {
        window.gtag('event', 'click', {
            'event_category': 'Criação de alerta',
            'event_label': `${title}`
        });
    }
    
    return (
        <ScreenStyle>
            <PageTitle>Apuração</PageTitle>
            <div className={`card`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        { !isLoading ? <span className='card-label fw-bold fs-3 mb-1'>Resultados: {data?.length}</span> : null }
                        
                    </h3>
                    <div className='d-flex py-1 justify-content-end'>
                    </div>
                    </div>
                <div className='card-body py-3'>
                
                    <div className='table-responsive'>
                        { !isLoading ? 
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-50px text-center'>ID Cupom</th>
                                    <th className='min-w-200px text-center'>Descrição</th>
                                    <th className='min-w-100px text-center'>Data Início</th>
                                    <th className='min-w-100px text-center'>Data Término</th>
                                    <th className='min-w-100px text-center '>Lojas Participantes</th>
                                    <th className='min-w-100px text-center'>Apurar</th>
                                </tr>
                            </thead>
                            <tbody>
                            { data.map((item:any) => { return (
                                <tr key={item.IDVoucher}>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold fs-6'>
                                            {item.IDVoucher}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.DescricaoCupom}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {formatDate(item.DataInicioVigenciaCupom)}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {formatDate(item.DataFimVigenciaCupom)}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.QuantidadeIDLojista}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <div className='flex-shrink-0'>
                                            <a href='#' className='disabled btn btn-primary btn-sm btn-light ms-2'>
                                                <KTSVG path='/media/icons/duotune/art/art006.svg' className='svg-icon-3' />
                                                Apurar
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )})}
                            </tbody>
                        </table> :
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                            <div className="preloader"></div>
                        </div> }
                    </div>
                </div>
            </div>
        </ScreenStyle>
    )
}

export default InvestigationScreen;