import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.div`
    .circle-style{fill:#25AE88;}
    .polyline-style{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}


`;

interface Props {
    onClick?: Function;
    className?: string;
}

const IconMagnifying: React.FC<Props> = ({ className, onClick }: Props) => {
    return (
        <StyledButton className={className}>
            <div>
                <div>
                    <span className="svg-icon svg-icon-1">
                        <svg width="800px" height="800px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet"><path fill="#9AAAB4" d="M27.388 24.642L24.56 27.47l-4.95-4.95l2.828-2.828z"></path><path fill="#66757F" d="M34.683 29.11l-5.879-5.879a2 2 0 0 0-2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l5.879 5.879a4 4 0 1 0 5.656-5.656z"></path><circle fill="#8899A6" cx="13.586" cy="13.669" r="13.5"></circle><circle fill="#BBDDF5" cx="13.586" cy="13.669" r="9.5"></circle></svg>
                    </span>
                </div>
            </div>
        </StyledButton>
    );
};
export default IconMagnifying;