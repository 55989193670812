import { Table } from './Table';

const columns = [
  { key: 'MesPedido', label: 'Mês Pedido' },
  { key: 'CNPJLojista', label: 'CNPJ' },
  { key: 'RazaoSocial', label: 'Razão Social' },
  { key: 'IDRegraPromocao', label: 'ID Regra Promoção' },
  { key: 'NomeCupom', label: 'Nome Cupom' },
  { key: 'NomeDistribuidor', label: 'Distribuidor' },
  { key: 'DataInicioVigenciaCupom', label: 'Data Início' },
  { key: 'DataFimVigenciaCupom', label: 'Data Término' },
  { key: 'FlgCupomUtilizado', label: 'Cupom Utilizado' },
  { key: 'ValorUtilizadoCupom', label: 'Valor Utilizado' },
];

export const DataTable = ({ data }: { data: any[] }) => (
  <div className="overflow-x-auto">
    <Table columns={columns} data={data} />
  </div>
);