import { FolderLock } from 'lucide-react';

export const EmptyState = () => (
  <div className="text-center py-12">
    <div className="bg-blue-50 inline-flex p-4 rounded-full mb-4">
      <FolderLock className="w-8 h-8 text-blue-500" />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 mb-2">Nenhum grupo encontrado</h3>
    <p className="text-gray-500">Crie seu primeiro grupo de permissões para começar</p>
  </div>
);