import { Table } from './Table';

const columns = [
  { key: 'MesPedido', label: 'Mês Pedido' },
  { key: 'CNPJLojista', label: 'CNPJ' },
  { key: 'RazaoSocial', label: 'Razão Social' },
  { key: 'IDRegraPromocao', label: 'ID Regra Promoção' },
  { key: 'IDPedido', label: 'ID Pedido' },
  { key: 'IDSku', label: 'ID Sku' },
  { key: 'GrupoProduto', label: 'Grupo Produto' },
  { key: 'SubGrupoProduto', label: 'Sub Grupo Produto' },
  { key: 'NomeRegional', label: 'Regional' },
  { key: 'NomeCompletoDistribuidor', label: 'Distribuidor' },
  { key: 'NomeCupom', label: 'Cupom' },
  { key: 'DataInicioVigenciaCupom', label: 'Data Início' },
  { key: 'DataFimVigenciaCupom', label: 'Data Término' },
  { key: 'FamiliaProduto', label: 'Família Produto' },
  { key: 'ValorUtilizadoCupom', label: 'Valor Utilizado' },
];

export const DataTable = ({ data }: { data: any[] }) => (
  <div className="overflow-x-auto">
    <Table columns={columns} data={data} />
  </div>
);