import styled from "@emotion/styled";
import { Modal } from 'react-bootstrap';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import { PageTitle } from "../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from 'react';
import * as API from '../../api';
import UploadModal from './../modals/UploadListModal';
import ConfirmModal from './../modals/ConfirmModal';
import { GetPermissions } from './../../modules/auth/core/Auth';
import Select from 'react-select';
import IconAccept from './../../components/IconAccept';
import IconRemove from './../../components/IconRemove';

const ScreenStyle = styled.div`
    position: relative;
    
    .new-rules-button {
        right: 0px;
    }
    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }
    
    .action-btn {
        transition: opacity 0.1s ease;
    }

    .action-btn:hover {
        opacity: 0.6;
    }

    .inactive-item {
        background-color: rgba(255, 0, 0, 0.1);
    }

    .filter-select {
    height: 42px;
    display: flex;
    justify-content: flex-end;

    .form-control {
      width: auto;
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' stroke='%23999' viewBox='0 0 24 24'%3e%3cpath d='M7 10l5 5 5-5H7z'/%3e%3c/svg%3e") no-repeat right 0.75rem center / 16px 12px;
      font-family: inherit;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
  }
`;

type Props = {
    className?: string
  }

const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    return new Date(dateString).toLocaleString('pt-BR', options);
}

const ListScreen:React.FC<Props> = ({className}) => {
    const permissions = GetPermissions();

    const [message, setMessage] = useState<string>('');
    const [confirmModal, showConfirmModal] = useState(false);
    const [selectedList, setSelectedList] = useState<any>(null);
    const [uploadModal, showUploadModal] = useState(false);
    const [uploadPromise, setUploadPromise] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>(''); 
    const [searchData, setSearchData] = useState<any>([]);
    const [resultsData, setResultsData] = useState<any>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [regionais, setRegionais] = useState<any>([]);
    const [selectedRegional, setSelectedRegional] = useState<any>({value:'all', label:'Regionais'});
    const [tamanhos, setTamanhos] = useState<any>([]);
    const [selectedTamanho, setSelectedTamanho] = useState<any>({value:'all', label:'Tamanhos'});
    const [bus, setBUs] = useState<any>([]);
    const [selectedBU, setSelectedBU] = useState<any>({value:'all', label:'BUs'});

    const CHANGE_TO:any = {
        "CENTRO NORTE":"CENTRO-NORTE", 
        "CENTRO OESTE":"CENTRO-OESTE", 
        "SAO PAULO":"SÃO PAULO", 
    }

    useEffect(() => {
        getRegionais();
        getTamanhos();
        getBUs();
        getLists();
    }, []);

    useEffect(() => {
        showConfirmModal(message != '');
    }, [message]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            searchRequest();
        }, 150);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm === '') {
            setResultsData(filterData(data));
        } else {
            setResultsData(filterData(searchData));
        }
    }, [data, searchData, selectedTamanho, selectedRegional, selectedBU]);

    useEffect(() => {
        showUploadModal(uploadPromise !== null);
    }, [uploadPromise]);

    const searchRequest = async () => {
        if (searchTerm === '') {
            setSearchData([]);
            return;
        }
        const response = await API.searchList(searchTerm);
        response.data.forEach((item:any) => {
            item.Regional = item.Regional.toUpperCase();
            if (CHANGE_TO[item.Regional]) {
                console.log(item.Regional);
                item.Regional = CHANGE_TO[item.Regional]
            }
        })
        if (response.success) setSearchData(response.data);
    }

    const filterData = (data:any) => {
        return data.filter((item:any) => {
            let matchesTamanho = selectedTamanho.value == 'all' || selectedTamanho.label === item.Tamanho;
            let matchesRegional = selectedRegional.value == 'all' || selectedRegional.label === item.Regional;
            let matchesBU = selectedBU.value == 'all' || selectedBU.label === item.BU;
            return matchesTamanho && matchesRegional && matchesBU;
        });
    }

    const getLists = async() => {
        setIsLoading(true);
        const response = await API.getLists();
        response.data.forEach((item:any) => {
            item.Regional = item.Regional.toUpperCase();
            if (CHANGE_TO[item.Regional]) {
                console.log(item.Regional);
                item.Regional = CHANGE_TO[item.Regional]
            }
        })
        
        setData(response.data);
        setIsLoading(false);
    }

    const getRegionais = async() => {
        const response = await API.getRegionalList();
        response.data.forEach((item:any) => {
            item.NomeRegional = item.NomeRegional.toUpperCase();
        });
        console.log(response.data);
        setRegionais(response.data);
    }

    const getTamanhos = async() => {
        const response = await API.getTamanhoList();
        setTamanhos(response.data);
    }

    const getBUs = async() => {
        const response = await API.getBUList();
        setBUs(response.data);
    }

    const updateStatus = async(list:any, status:boolean) => {
        if (list.is_actived == status) return;
        const response = await API.updateListStatus(list, status);
        if (response.success) {
            const updatedListData = data.map((item:any) =>
                item.IDLista === list.IDLista 
                    ? { ...item, is_actived: status } 
                    : item
            );
            setData(updatedListData);

            const updateSearchData = searchData.map((item:any) =>
                item.IDLista === list.IDLista 
                    ? { ...item, is_actived: status } 
                    : item
            );
            setSearchData(updateSearchData);
        }
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: any) => {
        const file:any = event.target.files[0];
        if (file) {
            const promise = API.uploadList(file);
            setUploadPromise(promise);
        }
    };

    return (
        <ScreenStyle>
            <PageTitle>Listas</PageTitle>
            <Modal
                id='modal_message'
                tabIndex={-1}
                dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
                show={uploadModal}
                onHide={() => setUploadPromise(null)}
                backdrop="static"
            >
                <UploadModal
                    promise={uploadPromise}
                    onClose={(update:boolean = false) => {
                        setUploadPromise(null);
                        if (update) getLists();
                    }}
                />
            </Modal>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-700px' show={confirmModal}>
                <ConfirmModal title={`${selectedList?.NomeLista}`} onClose={(value:any) => {
                    if (value) updateStatus(selectedList, !selectedList.is_actived);
                    setSelectedList(null);
                    setMessage('');
                }} message={message}/>
            </Modal>
            <div className="card card-custom gutter-b p-10">
                <h3 className='card-title align-items-start flex-column'>
                    { !isLoading ? <span className='card-label fw-bold fs-3 mb-1'>Listas cadastradas: {data?.length}</span> : null }
                </h3>
                <div className="mb-4"></div>
                { permissions?.some(feature => feature.name.includes('cashback-listas-add')) ? 
                <div className='d-flex py-1 justify-content-end'>
                    <div>
                        <a href='#' className='btn btn-primary btn-sm btn-light ms-2' onClick={handleUploadClick}>
                            Cadastrar Listas
                        </a>
                        <a href='https://sadataliteracyexternal.blob.core.windows.net/insight-experience/static/b96ef759-fc65-4631-8cd1-f3705d29aba6.xlsx' className='btn btn-secondary btn-sm btn-light ms-2'>
                            Baixar modelo
                        </a>
                        <input 
                            ref={fileInputRef} 
                            type="file" 
                            accept=".xlsx" 
                            style={{ display: "none" }} 
                            onChange={handleFileChange} 
                        />
                    </div>
                </div> : null }
                    
                <div className="d-flex monitor-alert-box">
                    <div className="form-item w-100">
                        <label htmlFor="" className="form-label">Sistema de Busca</label>
                        <div className="d-flex w-100">
                            <input
                                placeholder="Digite aqui o ID da Lista ou Nome"
                                type="text"
                                defaultValue={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-input form-control"
                            />
                            <span className="search-icon svg-icon svg-icon-1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                </svg>
                            </span>  
                        </div>
                    </div>
                    <div className="card card-custom gutter-b pt-9">
            <div className="d-flex monitor-alert-box">
              <div className="form-item w-100">
                <div className="d-flex w-100">
                    <Select 
                    className="w-250px form-select-solid form-item ms-5" 
                    value={selectedTamanho} 
                    placeholder={'Tamanho'}
                    options={[{ value: 'all', label: 'Tamanhos' }, ...tamanhos.map((item: any) => ({ value: item.IDTamanho, label: item.TamanhoLista }))]}
                    onChange={(e) => {
                      setSelectedTamanho(e);
                    }}
                  />
                  <Select 
                    className="w-250px form-select-solid form-item ms-4" 
                    value={selectedRegional} 
                    placeholder={'Regional'}
                    options={[{ value: 'all', label: 'Regionais' }, ...regionais.map((item: any) => ({ value: item.IDRegional, label: item.NomeRegional }))]}
                    onChange={(e) => {
                      setSelectedRegional(e);
                    }}
                  />
                  <Select 
                    className="w-250px form-select-solid form-item ms-4" 
                    value={selectedBU} 
                    placeholder={'BU'}
                    options={[{ value: 'all', label: 'BUs' }, ...bus.map((item: any) => ({ value: item.IDBu, label: item.GrupoFamilia }))]}
                    onChange={(e) => {
                      setSelectedBU(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
                </div>
            </div>
            <div className={`card mt-4`}>
                
                <div className='card-body py-3'>
                
                    <div className='table-responsive'>
                        { !isLoading ? 
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-50px text-center'>ID Lista</th>
                                    <th className='min-w-200px text-center'>Nome</th>
                                    <th className='min-w-100px text-center'>Tamanho</th>
                                    <th className='min-w-100px text-center'>Regional</th>
                                    <th className='min-w-100px text-center '>BU</th>
                                    { permissions?.some(feature => feature.name.includes('cashback-listas-edit-status')) ? <th className='min-w-100px text-center'>Ações</th> : null }
                                </tr>
                            </thead>
                            <tbody>
                            { resultsData.map((item:any) => { return (
                                <tr key={item.IDLista} className={item.is_actived ? '' : 'inactive-item'}>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold fs-6'>
                                            {item.IDLista}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.NomeLista}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.Tamanho}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.Regional}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.BU}
                                        </span>
                                    </td>
                                    { permissions?.some(feature => feature.name.includes('cashback-listas-edit-status'))  ? 
                                    <td className="text-center">
                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                            <a href='#' className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 action-btn ${item.is_actived ? 'disabled' : ''}`} onClick={() => {
                                                setMessage(`Deseja ativar essa lista?`);
                                                setSelectedList(item)}}>
                                                <IconAccept className={'svg-icon-3'}></IconAccept>
                                            </a>
                                            <a href='#' className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm action-btn ${!item.is_actived ? 'disabled' : ''}`} onClick={() => {
                                                setMessage(`Deseja desativar essa lista?`);
                                                setSelectedList(item)}}>
                                                <IconRemove className={'svg-icon-3'}></IconRemove>
                                            </a>
                                        </div>
                                    </td> : null }
                                </tr>
                            )})}
                            </tbody>
                        </table> :
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                            <div className="preloader"></div>
                        </div> }
                    </div>
                </div>
            </div>
        </ScreenStyle>
    )
}

export default ListScreen;