import { FC, useState, useEffect } from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import styled from '@emotion/styled';

const Style = styled.div`
  .modal-container {
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }
`;

type Props = {
    onClose: Function,
    data: any
}

const types:any = {
    'coupon-lojista-not-found': { 
        columns:['IDRegraPromocao', 'IDLojista', 'DescricaoCupom', 'BeneficioValor'], 
        names:['ID Regra Promoção', 'ID Lojista', 'Cupom', 'Valor IFC'] 
    },
    'coupon-lojista-without-voucher': { 
        columns:['IDLojista', 'ValorLoyalty', 'CNPJ'], 
        names:['ID Lojista', 'Valor Loyalty', 'CNPJ'] 
    },
    'coupon-incorrect-valor-loyalty': { 
        columns:['IDRegraPromocao', 'IDLojista', 'DescricaoCupom', 'BeneficioValor', 'valorLoyalty'], 
        names:['ID Regra Promoção', 'ID Lojista', 'Cupom', 'Valor IFC', 'Valor Loyalty'] 
    },
    'coupon-incorrect-beneficio-valor': { 
        columns:['IDRegraPromocao', 'IDLojista', 'DescricaoCupom', 'BeneficioValor'], 
        names:['ID Regra Promoção', 'ID Lojista', 'Cupom', 'Valor IFC'] 
    }
}

const AlertDetailsModal: FC<Props> = (props) => {
    const [data, setData] = useState<any>(null);
    const [alertData, setAlertData] = useState<any>(null);
    const [columns, setColumns] = useState<string[]>([]);
    const [names, setNames] = useState<string[]>([]);

    const defaultColumns = ['IDPedidoAtivacao', 'IDRegraPromocaoAtivacao', 'NomePromocao', 'BU', 'Tamanho', 'NomeRegional'];
    const defaultNames = ['ID Pedido', 'ID Promoção', 'Nome Promoção', 'BU', 'Tamanho', 'Regional'];

    useEffect(() => {
        if (!props.data) return;
        setData(props.data);

        if (props.data.type && types[props.data.type]) {
            setColumns(types[props.data.type].columns);
            setNames(types[props.data.type].names);
            getData();
        } else {
            setColumns(defaultColumns);
            setNames(defaultNames);
        }
    }, [props.data]);

    const getData = async() => {
        const response = await API.getAlertData(props.data.id_alert);
        try {
            const json = JSON.parse(response.data.data);
            console.log(json.data);
            setAlertData(json.data);
        } catch (e) {
            console.log(e);
        }
        
        console.log(props.data.id_alert, response);
    }

    function formatCNPJ(cnpj:string) {
        if (!cnpj) return '';
        cnpj = cnpj.replace(/\D/g, '');
        if (cnpj.length !== 14) {
            return cnpj;
        }
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }

    return (
        <Style>
            <div className='modal-container text-center justify-content-center'>
                <div className='px-7 py-5 d-flex text-center justify-content-between'>
                    { data ? <div className='pt-2 fs-3 text-dark fw-bolder'>{`${data ? data.name : ''}`}</div> : null }
                    <div>
                        <BtnClose onClick={() => props.onClose()} />
                    </div>
                </div>
                <div className='separator border-gray-200'></div>
                { alertData ? 
                <div className="card card-custom gutter-b p-10">
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {names.map((name, index) => (
                                    <th key={`${name}_${index}`} className='min-w-100px text-center'>{name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {alertData?.map((item:any, index:any) => (
                                <tr key={`${index}`}>
                                    {columns.map((col, colIndex) => (
                                        <td key={colIndex} className="text-center">
                                            <span className='text-dark fw-semibold d-block fs-6'>
                                                {item[col]}
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> : 
                <div className="justify-content-center text-center mb-4">
                    <p className="mb-5 mt-5">Carregando, aguarde um momento</p>
                    <div className="preloader"></div>
                </div> }
            </div>
        </Style>
    )
}

export default AlertDetailsModal;