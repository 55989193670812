import { FC, useState, useEffect } from 'react';
import { X } from 'lucide-react';

type Props = {
  onClose: () => void;
  data: any;
};

const DetailsModal: FC<Props> = ({ onClose, data }) => {
  const formatCNPJ = (cnpj: string) => {
    cnpj = cnpj.replace(/\D/g, '');
    if (cnpj.length !== 14) {
      return cnpj;
    }
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  };

  const columns = [
    { key: 'IDPedidoAtivacao', label: 'ID Pedido' },
    { key: 'IDRegraPromocaoAtivacao', label: 'ID Promoção' },
    { key: 'NomePromocao', label: 'Nome Promoção' },
    { key: 'BU', label: 'BU' },
    { key: 'Tamanho', label: 'Tamanho' },
    { key: 'NomeRegional', label: 'Regional' },
  ];

  if (!data) return null;

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden max-h-[90vh]">
      {/* Header */}
      <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
        <h3 className="text-lg font-semibold text-white">
          {`${data.RazaoSocial} - ${formatCNPJ(data.CNPJLojista)}`}
        </h3>
        <button
          onClick={onClose}
          className="text-white/80 hover:text-white hover:bg-white/10 rounded-lg p-2 transition-colors"
        >
          <X className="w-5 h-5" />
        </button>
      </div>

      {/* Content */}
      <div className="p-6 overflow-y-auto max-h-[calc(90vh-4rem)]">
        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.key}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap text-center"
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.details.map((item: any, index: number) => (
                  <tr key={`${item.IDPedidoAtivacao}_${index}`} className="hover:bg-gray-50">
                    {columns.map((column) => (
                      <td
                        key={`${index}-${column.key}`}
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center"
                      >
                        {item[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;