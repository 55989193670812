import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.div`
  /* You can add styles here if needed */
`;

interface Props {
  onClick?: Function;
  className?: string;
}

const IconRemove: React.FC<Props> = ({ onClick, className }: Props) => {
  return (
    <StyledButton className={className}>
      <div>
        <div>
          <span className="svg-icon svg-icon-1">
            <svg
              version="1.1"
              id="Uploaded_to_svgrepo.com"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="800px"
              height="800px"
              viewBox="0 0 32 32"
              xmlSpace="preserve"
            >
              <g>
                <circle style={{ fill: '#E54D2E' }} cx="16" cy="16" r="13" />
                <path
                  style={{ fill: '#F27261' }}
                  d="M16,3v26c7.18,0,13-5.82,13-13C29,8.82,23.18,3,16,3z"
                />
                <path
                  style={{ fill: '#F4D6B0' }}
                  d="M16,3c7.168,0,13,5.832,13,13s-5.832,13-13,13S3,23.168,3,16S8.832,3,16,3 M16,0 
                  C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0L16,0z M18.121,16l2.475-2.475c0.586-0.585,0.586-1.536,0-2.121 
                  c-0.586-0.586-1.535-0.586-2.121,0L16,13.879l-2.475-2.475c-0.586-0.586-1.535-0.586-2.121,0c-0.586,0.585-0.586,1.536,0,2.121 
                  L13.879,16l-2.475,2.475c-0.586,0.585-0.586,1.536,0,2.121c0.293,0.293,0.677,0.439,1.061,0.439s0.768-0.146,1.061-0.439L16,18.121 
                  l2.475,2.475c0.293,0.293,0.677,0.439,1.061,0.439s0.768-0.146,1.061-0.439c0.586-0.585,0.586-1.536,0-2.121L18.121,16z"
                />
              </g>
            </svg>
          </span>
        </div>
      </div>
    </StyledButton>
  );
};

export default IconRemove;