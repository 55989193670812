import { PageTitle } from '../../../_metronic/layout/core'
import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {KTSVG } from '../../../_metronic/helpers'
import * as API from '../../api';
import {Modal} from 'react-bootstrap';
import CompanyModal from './CompanyModal';
import ModalConfirm from "./../dashboard/ModalConfirm";
import BtnEdit from "../../components/BtnEdit";
import BtnTrash from '../../components/BtnTrash';

const Styled = styled.div`
    
    .remove-user-title {
        margin:20px !important;
    }

    svg {
        max-width: 20px;
        opacity: 0.7;
    }    
`;

const Users:FC = () => {

    const [selectedCompany, setSelectedCompany] = useState<any>();
    const [companys, setCompanys] = useState<any[]>([]);
    const [modal, showModal] = useState<boolean>(false);
    const [removeModal, showRemoveModal] = useState<boolean>(false);

    function onCompanyCreated() {
        updateData();
        showModal(false);
        setSelectedCompany(null);
        document.location.href = "/companys";
    }

    function onModalClose() {
        showModal(false);
        setSelectedCompany(null);
    }

    function updateData() {
        API.listCompanys().then((response) => {
            setCompanys(response.data);
        });
    }

    function onEditCompanyClick(company:any) {
        setSelectedCompany(company);
        showModal(true);
    }
    
    function onRemoveCompanyClick(company:any) {
        setSelectedCompany(company);
        showRemoveModal(true);
    }

    async function closeRemoveModal(response:boolean) {
		if (response && selectedCompany) {
			await API.deleteCompany(selectedCompany.guid);
            updateData();
		} 
		setSelectedCompany(null);
		showRemoveModal(false);
	}


    useEffect(() => {
        updateData();
    }, []);

    return <Styled>
        <PageTitle>Administração de Empresas</PageTitle>
        <Modal id='kt_modal_create_app' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={modal}>
            <CompanyModal companyData={selectedCompany} onCompanyCreated={() => { onCompanyCreated() }} onCompanyUpdated={() => { onCompanyCreated()}} onClose={() => { onModalClose() }}/>
        </Modal>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-400px' show={removeModal}>
			<ModalConfirm onClose={closeRemoveModal} message={`Você tem certeza que deseja remover a empresa \n${selectedCompany?.name}?`}/>
		</Modal>
        <div className={`card`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Lista de Empresas</span>
                </h3>
                <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a company'>
                    <button onClick={() => { showModal(true) }} className='btn btn-sm btn-light-primary'>
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        Adicionar Empresa
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold '>
                                
                                <th className='min-w-150px'>Empresa</th>
                                <th className='min-w-140px'>Membros</th>
                                <th className='min-w-120px'>Status</th>
                                <th className='min-w-100px text-end'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>{ companys.map((company:any) => { return (
                            <tr key={company.guid}>
                                
                                <td>
                                    <div className='d-flex align-items-center'>
                                        
                                        <div className='d-flex justify-content-start flex-column'>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>{company.name}</span>        
                                        </div>
                                    </div>
                                </td>
                                
                                <td>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{company.members} {company.members == 1 ? `Membro` : 'Membros'}</span>
                                </td>
                                <td>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{company.is_actived ? 'Ativo' : 'Desativado'}</span>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                        
                                        <button onClick={() => { onEditCompanyClick(company)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                            <BtnEdit/>
                                        </button>
                                        <button onClick={() => { onRemoveCompanyClick(company)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                            <BtnTrash/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            )})}</>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Styled>

}

export default Users;

