import { FC, useState, useEffect } from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import styled from '@emotion/styled';

const Style = styled.div`
  .modal-container {
    width: 100%;
    max-height: 70vh; /** Add this to control the height */
    overflow-y: auto; /** Add this to enable scrolling inside the modal */
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }
`;

type Props = {
    onClose: Function,
    store?: any,
    jobId?: String
}

const VouchersListModal: FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [url, setURL] = useState(null);
    const [error, setError] = useState(false);
    const [storeData, setStoreData] = useState([]);
    const limit = 2000000;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //loadVouchersList();
    }, []);

    const loadVouchersList = async() => {
        setIsLoading(true);
        const response = await API.getVouchersByStore(props.store.IDLojista);
        setStoreData(response.data);
        setIsLoading(false);
    }

    useEffect(() => {
      if (props.store) {
        loadVouchersList();
      }
    }, [props.store]);

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString); 
        //date.setHours(date.getHours() + 3); // Adiciona 3 horas
        const isoString = date.toISOString();
        const year = isoString.substring(0, 4);
        const month = isoString.substring(5, 7);
        const day = isoString.substring(8, 10);
        const hour = isoString.substring(11, 13);
        const minute = isoString.substring(14, 16);
        const second = isoString.substring(17, 19);  
        return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    };

    return (
        <Style>
            <div className='modal-container text-center justify-content-center'>
                <div className='px-7 py-5 d-flex text-center justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>{props.store?.RazaoSocial}</div>
					<div>
						<BtnClose onClick={() => props.onClose()} />
					</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className="card card-custom gutter-b p-10">
                    { !isLoading ?
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-50px text-center'>ID Cupom</th>
                                <th className='min-w-250px text-center'>Descrição</th>
                                <th className='min-w-150px text-center'>Data Início</th>
                                <th className='min-w-150px text-center'>Data Término</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storeData.map((item:any) => (
                                <tr key={item.IDVoucher}>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold fs-6'>
                                            {item.IDVoucher}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.DescricaoCupom}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {formatDate(item.DataInicioVigenciaCupom)}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {formatDate(item.DataFimVigenciaCupom)}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> :
                    <div className='justify-content-center text-center mb-4'>
                        <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                        <div className="preloader"></div>
                    </div> }
                </div>
            </div>
        </Style>
    )
}

export default VouchersListModal;