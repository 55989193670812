import React, { FC, useState, useEffect, useRef } from 'react';
import { ThumbsUp, ThumbsDown, Send, Loader2, Bot, User } from 'lucide-react';
import { MessageModel, UserInfoModel, defaultUserInfos } from '../../../_metronic/helpers';
import { getUserData } from '../../modules/auth';
import * as API from '../../api';
import TableDisplay from './TableDisplay';
import { sha256 } from 'crypto-hash';

type Props = {
  isDrawer?: boolean;
};

const Chat: FC<Props> = ({ isDrawer = false }) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [lastMessage, setLastMessage] = useState<string>('');
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [counter, setCounter] = useState(0);
  const [session, setSession] = useState<string>('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [user, setUser] = useState<any>(null);

  const firstAIMessage = 'Olá! Em que posso te ajudar?';
  const waitAIMessage = 'Aguarde, estou buscando informações na base de dados...';


  useEffect(() => {
    scrollToBottom();
  }, [messages]);

    const createMessage = (isUser: Boolean, text: string, query: string = ''): MessageModel => {
      return {
        user: isUser ? 2 : 1,
        type: isUser ? 'out' : 'in',
        text: text,
        time: 'Just now',
        div: null,
        query
      };
    };

  	const sendMessage = async(isUser: Boolean) => {
      const hash = await sha256(new Date().getTime().toString() + Math.random());
      const newMsg: MessageModel = createMessage(true, message);
      newMsg.hash = hash;
      setMessages(prevMessages => [...prevMessages, newMsg]);
      toggleChatUpdateFlat(!chatUpdateFlag);
      setLastMessage(message);
      setMessage('');
      if (isUser) {
        request(message);
      }
  	};

    const scrollToBottom = async() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

	const aiMessage = async(type: string, data: any, query: string = '') => {
		let message = null;
		let tableContent = null;
		if (type === 'text') {
			message = data;
		} else {
			const resultData = data.statement_response.result;
			const manifestData = data.statement_response.manifest;

			const columns = manifestData.schema.columns;
			let rows = resultData.data_typed_array;
			if (rows) rows = rows.slice(0, 50);
			if (columns && rows) {
				tableContent = <TableDisplay key={Math.random()} columns={columns} rows={rows} />;
				message = tableContent
			}
		}
    const hash = await sha256(new Date().getTime().toString() + Math.random());
		const messageObj = createMessage(false, message, query);
    messageObj.hash = hash;
		messageObj.div = tableContent
		//messages.push(messageObj);
		//setMessages(() => messages);
    setMessages(prevMessages => [...prevMessages, messageObj]);
		toggleChatUpdateFlat((flag) => !flag);
		return messageObj;
	};

  const createSessionHash = async() => {
    const hash = await sha256(new Date().getTime().toString() + Math.random());
    setSession(hash);
  }

	useEffect(() => {
		aiMessage('text', firstAIMessage);
    createSessionHash();
    setUser(getUserData());
		/*const intervalId = setInterval(() => {
		  if (scrollBottom) scrollToBottom();
		}, 300);
	
		return () => {
		  clearInterval(intervalId);
		};*/
	 }, []);
	

  useEffect(() => {
    if (conversationId) request(lastMessage);
  }, [conversationId]);

	const request = async (message: any) => {
    setIsRequesting(true);
		if (conversationId) {
			const response = await API.sendAIMessage({ conversationId, message });
			const aiMessageObj = await aiMessage('text', '...');
      let query = null;
			let type = null;
			for (let a = 0; a < 10; a++) {
				await sleep(5000);
				const checkResponse: any = await API[type === 'query' ? 'checkAIQuery' : 'checkAIMessage'](conversationId, response.data.id);
				const attachment = checkResponse.data?.message?.attachments || checkResponse.data?.attachments?.[0];
				if (!type && attachment) {
					if (attachment.text) {
						aiMessageObj.text = attachment.text.content;
						setCounter(counter + 1);
						type = 'text';
            setIsRequesting(false);
						break;
					} else if (attachment.query) {
						if (attachment.query.description) {
							aiMessageObj.text = waitAIMessage;
              query = attachment.query.query;
							setCounter(counter + 1);
							type = 'query';
							continue;
						}
					}
				}
				if (checkResponse.data?.statement_response && checkResponse.data.statement_response.status.state == 'SUCCEEDED') {
					await aiMessage('query', checkResponse.data, query);
          setIsRequesting(false);
					break;
				}
			}
		} else {
			const response: any = await API.createAIConversation(message);
			setConversationId(response.data.conversation.id);
		}
	};

  async function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const setMessageRating = async(message:MessageModel, index:number, value:string) => {
    message.rating = value;
    setCounter(counter + 1);
    const user_message = messages[index - 1].type == 'in' ? messages[index - 2].text : messages[index - 1].text;
    const ai_message = message.query != '' ? '' : message.text;
    const obj = {user_message, ai_message, query:message.query, is_positive:value == 'yes', hash:message.hash, session };
    await API.aiMessageRating(obj);
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isRequesting) return;
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage(true);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-230px)]">
      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto px-6 py-4 space-y-6">
        {messages.map((message, index) => (
          <div
            key={`message-${index}-${Math.random()}`}
            className={`flex ${message.type === 'in' ? '' : 'justify-end'}`}
          >
            <div className={`flex max-w-[80%] ${message.type === 'in' ? 'flex-row' : 'flex-row-reverse'}`}>
              {/* Avatar */}
              <div className="flex-shrink-0">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-100">
                  {message.type === 'in' ? (
                    <Bot className="w-6 h-6 text-blue-600" />
                  ) : 
                    user.src ? 
                      <img alt='Pic' className='rounded' src={ getUserData().src}/> : <User className="w-6 h-6 text-blue-600" />
                    }
                </div>
              </div>

              {/* Message Content */}
              <div className={`flex flex-col ${message.type === 'in' ? 'ml-4' : 'mr-4'}`}>
                <span className="text-sm text-gray-500 mb-1">
                  {message.type === 'in' ? 'Compra AI' : 'Você'}
                </span>
                
                {message.div ? (
                  <div className="space-y-4">
                    {message.div}
                    {message.text !== firstAIMessage && 
                     message.text !== '...' && 
                     message.text !== waitAIMessage && 
                     message.type === 'in' && (
                      <FeedbackButtons
                        message={message}
                        index={index}
                        onRate={setMessageRating}
                      />
                    )}
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className={`rounded-2xl px-4 py-2 ${
                      message.type === 'in' 
                        ? 'bg-gray-100 text-gray-900' 
                        : 'bg-blue-600 text-white'
                    }`}>
                      <div dangerouslySetInnerHTML={{ __html: message.text }} />
                    </div>
                    {message.text !== firstAIMessage && 
                     message.text !== '...' && 
                     message.text !== waitAIMessage && 
                     message.type === 'in' && (
                      <FeedbackButtons
                        message={message}
                        index={index}
                        onRate={setMessageRating}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <div className="border-t border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-end space-x-4">
          <textarea
            className="flex-1 min-h-[2.5rem] max-h-32 rounded-lg border border-gray-200 p-3 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none"
            placeholder="Escreva uma mensagem..."
            rows={1}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          />
          <button
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => sendMessage(true)}
            disabled={isRequesting}
          >
            {isRequesting ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <>
                <Send className="w-5 h-5 mr-2" />
                Enviar
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

// Feedback Buttons Component
const FeedbackButtons = ({ message, index, onRate }: { 
  message: MessageModel; 
  index: number; 
  onRate: (message: MessageModel, index: number, value: string) => void;
}) => (
  <div className="flex space-x-2">
    <button
      onClick={() => onRate(message, index, 'yes')}
      className={`p-2 rounded-lg transition-colors duration-200 ${
        message.rating === 'yes'
          ? 'bg-green-100 text-green-600'
          : 'hover:bg-gray-100 text-gray-400'
      }`}
      title="Marcar como uma resposta boa"
    >
      <ThumbsUp className="w-5 h-5" />
    </button>
    <button
      onClick={() => onRate(message, index, 'no')}
      className={`p-2 rounded-lg transition-colors duration-200 ${
        message.rating === 'no'
          ? 'bg-red-100 text-red-600'
          : 'hover:bg-gray-100 text-gray-400'
      }`}
      title="Marcar como uma resposta ruim"
    >
      <ThumbsDown className="w-5 h-5" />
    </button>
  </div>
);

export { Chat };