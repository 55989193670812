import styled from "@emotion/styled";
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { PageTitle } from "../../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import * as API from '../../../api';
import PreloaderIcon from '../../../components/PreloaderIcon';
import WorksheetIcon from './../../../components/WorksheetIcon';
import ReportCurrent from './AtivacaoReportCurrent';
import ReportMonth from './AtivacaoReportMonth';
import { GetPermissions } from './../../../modules/auth/core/Auth';

const ScreenStyle = styled.div`
    position: relative;
    
    .new-rules-button {
        right: 0px;
    }
    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }

    .preloader {
        position: relative;
        display: inline-table;
        width: 32px;
        height: 32px;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>')
    }

    .nav-item {
        cursor:pointer;
      }
`;

type Props = {
    className?: string
  }

const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    return new Date(dateString).toLocaleString('pt-BR', options);
}

const ReportAtivacao:React.FC<Props> = ({className}) => {
    const permissions = GetPermissions();
    const [activeTab, setActiveTab] = useState('current');

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>([]);    

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        setActiveTab(permissions?.some(feature => feature.name.includes('cashback-relatorios-ativacao-currently')) ? 'current' : 'month');
    }, [permissions]);
    
    return (
        <ScreenStyle>
            <PageTitle>Relatório - Ativação Valores</PageTitle>
            <div className={`card`}>
                <div className=''>
                    <ul className="nav nav-tabs nav-bold nav-tabs-line">
                        { permissions?.some(feature => feature.name.includes('cashback-relatorios-ativacao-currently')) ? 
                        <li className="nav-item">
                            <a className={`nav-link ${activeTab === 'current' ? 'active' : ''}`} onClick={() => handleTabChange('current')}>
                            <span className="nav-icon">
                                <i className="flaticon2-chat-1"></i>
                            </span>
                            <span className="nav-text">Mês Corrente</span>
                            </a>
                        </li> : null }
                        { permissions?.some(feature => feature.name.includes('cashback-relatorios-ativacao-month')) ? 
                        <li className="nav-item">
                            <a className={`nav-link ${activeTab === 'month' ? 'active' : ''}`} onClick={() => handleTabChange('month')}>
                            <span className="nav-icon">
                                <i className="flaticon2-drop"></i>
                            </span>
                            <span className="nav-text">Mês Fechado</span>
                            </a>
                        </li> : null }
                    </ul>
                    <div className="card-body bg-gray-50">
                        <div className="tab-content">
                            { permissions?.some(feature => feature.name.includes('cashback-relatorios-ativacao-currently')) ? 
                            <div className={`tab-pane fade show ${activeTab === 'current' ? 'active' : ''}`} id="current">
                                {activeTab === 'current' ? <ReportCurrent/> : <></>}
                            </div> : null }

                            { permissions?.some(feature => feature.name.includes('cashback-relatorios-ativacao-month')) ? 
                            <div className={`tab-pane fade show ${activeTab === 'month' ? 'active' : ''}`} id="month">
                                {activeTab === 'month' ? <ReportMonth/> : <></>}
                            </div> : null }
                        </div>
                    </div>
                </div>
            </div>
        </ScreenStyle>
    )
}

export default ReportAtivacao;