import React, { FC } from 'react';
import { AlertTriangle, X } from 'lucide-react';

interface Props {
  onClose: (confirmed: boolean) => void;
  message: string;
  title?: string;
}

const ConfirmModal: FC<Props> = ({ onClose, message, title }) => {
  return (
    <div className="bg-white rounded-2xl shadow-xl overflow-hidden max-w-md w-full mx-auto animate-scale-up">
      {/* Header */}
      <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-white/10 rounded-lg">
            <AlertTriangle className="w-5 h-5 text-white" />
          </div>
          <h3 className="text-lg font-semibold text-white">
            {title || 'Confirmar Ação'}
          </h3>
        </div>
        <button
          onClick={() => onClose(false)}
          className="text-white/80 hover:text-white hover:bg-white/10 rounded-lg p-2 transition-colors"
        >
          <X className="w-5 h-5" />
        </button>
      </div>

      {/* Content */}
      <div className="px-6 py-8">
        <p className="text-gray-600 text-center leading-relaxed">{message}</p>
      </div>

      {/* Actions */}
      <div className="px-6 py-4 bg-gray-50 flex justify-center space-x-4">
        <button
          onClick={() => onClose(true)}
          className="px-6 py-2.5 bg-green-600 text-white font-medium rounded-lg hover:bg-green-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          Confirmar
        </button>
        <button
          onClick={() => onClose(false)}
          className="px-6 py-2.5 bg-gray-200 text-gray-700 font-medium rounded-lg hover:bg-gray-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Cancelar
        </button>
      </div>

      <style>{`
        .animate-scale-up {
          animation: scaleUp 0.2s ease-out;
        }

        @keyframes scaleUp {
          from {
            transform: scale(0.95);
            opacity: 0;
          }
          to {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default ConfirmModal;