import { FC, useState, useEffect } from 'react';
import { FileDown, Loader2, X, AlertCircle, CheckCircle } from 'lucide-react';

type Props = {
  onClose: Function;
  url?: string;
  promise: any;
  error?: boolean;
};

const DownloadModal: FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [url, setURL] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [promise, setPromise] = useState<any>(null);

  useEffect(() => {
    if (!props.promise) return;
    setPromise(props.promise);
  }, [props.promise]);

  useEffect(() => {
    if (!promise) return;
    setLoading(true);
    promise
      .then((result: any) => {
        setLoading(false);
        setURL(`${process.env.REACT_APP_API_URL}${result.link}`);
      })
      .catch((e: any) => {
        setError(true);
      });
  }, [promise]);

  useEffect(() => {
    setError(false);
    setURL(null);
  }, []);

  useEffect(() => {
    if (props.url) {
      setLoading(false);
      setURL(props.url);
    }
  }, [props.url]);

  const getStatusContent = () => {
    if (loading && !props.error && !error) {
      return {
        icon: <Loader2 className="w-12 h-12 text-blue-500 animate-spin" />,
        title: 'Gerando relatório',
        message: 'Aguarde, essa solicitação pode levar alguns minutos',
      };
    } else if (props.error || error) {
      return {
        icon: <AlertCircle className="w-12 h-12 text-red-500" />,
        title: 'Erro ao gerar relatório',
        message: 'Tente novamente mais tarde',
      };
    }
    return {
      icon: <CheckCircle className="w-12 h-12 text-green-500" />,
      title: 'Relatório gerado com sucesso!',
      message: 'Seu download está pronto',
    };
  };

  const status = getStatusContent();

  return (
    <div className="bg-white rounded-2xl shadow-xl overflow-hidden max-w-md w-full mx-auto animate-scale-up">
      {/* Header */}
      <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
        <h3 className="text-lg font-semibold text-white">{status.title}</h3>
        {(!loading || props.error || error) && (
          <button
            onClick={() => props.onClose()}
            className="text-white/80 hover:text-white hover:bg-white/10 rounded-lg p-2 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        )}
      </div>

      {/* Content */}
      <div className="px-6 py-8">
        <div className="flex flex-col items-center text-center space-y-4">
          <div className="p-3 bg-gray-50 rounded-full">
            {status.icon}
          </div>
          <p className="text-gray-600">{status.message}</p>

          {!loading && !error && !props.error && url && (
            <a
              href={url}
              className="mt-6 inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 font-medium shadow-sm hover:shadow gap-2"
            >
              <FileDown className="w-5 h-5" />
              Baixar Relatório
            </a>
          )}
        </div>
      </div>

      <style>{`
        .animate-scale-up {
          animation: scaleUp 0.2s ease-out;
        }

        @keyframes scaleUp {
          from {
            transform: scale(0.95);
            opacity: 0;
          }
          to {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default DownloadModal;