import { FC, useState, useEffect, createContext, useContext, useRef, Dispatch, SetStateAction } from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {getGuidelines} from './../../../api';
import {WithChildren} from '../../../../_metronic/helpers'
import * as API from '../../../../app/api';

import { createStore } from 'redux'


type AuthContextProps = {
	auth: AuthModel | undefined
	saveAuth: (auth: AuthModel | undefined) => void
	currentUser: UserModel | undefined
	setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
	logout: () => void;
	permissions: any[];
}

const initAuthContextPropsState = {
	auth: authHelper.getAuth(),
	saveAuth: () => {},
	currentUser: undefined,
	setCurrentUser: () => {},
	logout: () => {},
	permissions: []
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
	return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
	const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
	const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
	const [permissions, setPermissions] = useState<any[]>([]);
	const saveAuth = (auth: AuthModel | undefined) => {
		setAuth(auth)
		if (auth) {
			authHelper.setAuth(auth)
		} else {
			authHelper.removeAuth()
		}
	}

	const logout = () => {
		saveAuth(undefined)
		setCurrentUser(undefined)
		localStorage.removeItem("current_company");
	}

	const requestPermissions = async () => {
		const result = await API.getPermissions();
		const groups = await API.getGroupPermissions();
		const userPermissions = getUserData().permissions || [];
	
		// Cria um conjunto para armazenar todas as permissões derivadas dos grupos
		const groupPermissionsSet = new Set();
	
		// Percorre cada grupo e adiciona as permissões ao conjunto se o usuário tiver permissão para o grupo
		groups.data.forEach((group:any) => {
			const groupPermissions = JSON.parse(group.data);
			groupPermissions.forEach((permission:any) => {
				if (userPermissions.includes(group.id_feature_group)) {
					groupPermissionsSet.add(permission);
				}
			});
		});
	
		// Transforma o conjunto de permissões em uma lista para posterior comparação
		const groupPermissionsList = Array.from(groupPermissionsSet);
	
		// Filtra os dados de 'result.data' para incluir apenas aqueles com 'id_feature' presente em 'groupPermissionsList'
		const data = result.data.filter((item:any) => groupPermissionsList.includes(item.id_feature));
	
		setPermissions(data);
	};

	useEffect(() => {
		if (!auth) return;
		requestPermissions();
	}, [auth]);

	return (
		<AuthContext.Provider
		  value={{
			auth,
			saveAuth,
			currentUser,
			setCurrentUser,
			logout,
			permissions
		  }}
		>
		  {children}
		</AuthContext.Provider>
	);
}

const AuthInit: FC<WithChildren> = ({children}) => {
  // const [guidelines, setGuidelines] = useState(null);
	const {auth, logout, setCurrentUser} = useAuth()
	const didRequest = useRef(false)
	const [showSplashScreen, setShowSplashScreen] = useState(true)
	
	useEffect(() => {
		const requestUser = async (apiToken: string) => {
			try {
				if (!didRequest.current) {
					const response:any = await getUserByToken(apiToken)
					if (response.data.success) {
						localStorage.setItem('auth', JSON.stringify(response.data.data));
						setCurrentUser(response.data);
					}
				}
			} catch (error) {
				if (!didRequest.current) {
					logout()
				}
			} finally {
				setShowSplashScreen(false)
			}
			return () => (didRequest.current = true)
		}
		if (auth && auth.api_token) {
			requestUser(auth.api_token)
		} else {
			logout()
			setShowSplashScreen(false)
		}
	}, []); 

	

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

const getUserData = () => {
	const authData:string = localStorage.getItem('auth') as string;
	try {
		return JSON.parse(authData);
	} catch (e){
		console.log(e);
	}
	return {};
}

const isAdmin = () => {
	const data = getUserData();
	return data && (data.is_admin || data.is_superadmin);
}

const isSuperAdmin = () => {
	const data = getUserData();
	return data && data.is_superadmin;
}

const GetPermissions = () => {
	const { permissions } = useContext(AuthContext);
	return permissions;
  };

export {AuthProvider, AuthInit, useAuth, getUserData, isAdmin, isSuperAdmin, GetPermissions }
