import styled from "@emotion/styled";
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from "../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from 'react';
import * as API from '../../api';
import StoreListModal from './../modals/StoreListModal';
import UploadModal from './../modals/UploadVouchersModal';
import { GetPermissions } from './../../modules/auth/core/Auth';

const ScreenStyle = styled.div`
  position: relative;

  .new-rules-button {
    right: 0px;
  }
  .search-icon {
    position: relative;
    padding-top: 8px;
    right: 35px;
  }

  .inactive-item {
    background-color: rgba(255, 0, 0, 0.1);
  }

  .action-btn {
    transition: opacity 0.1s ease;
  }

  .action-btn:hover {
    opacity: 0.6;
  }

  .filter-select {
    height: 42px;
    display: flex;
    justify-content: flex-end;

    .form-control {
      width: auto;
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' stroke='%23999' viewBox='0 0 24 24'%3e%3cpath d='M7 10l5 5 5-5H7z'/%3e%3c/svg%3e") no-repeat right 0.75rem center / 16px 12px;
      font-family: inherit;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
  }
`;

type Props = {
  className?: string;
};

const formatDate = (dateString: string): string => {
    const date = new Date(dateString); 
    //date.setHours(date.getHours() + 3); // Adiciona 3 horas
    const isoString = date.toISOString();
    const year = isoString.substring(0, 4);
    const month = isoString.substring(5, 7);
    const day = isoString.substring(8, 10);
    const hour = isoString.substring(11, 13);
    const minute = isoString.substring(14, 16);
    const second = isoString.substring(17, 19);  
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
};

const VoucherScreen: React.FC<Props> = ({ className }) => {
  const permissions = GetPermissions();

  const [uploadModal, showUploadModal] = useState(false);
  const [uploadPromise, setUploadPromise] = useState<any>(null);
  const [storeModal, showStoreModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchData, setSearchData] = useState<any>([]);
  const [resultsData, setResultsData] = useState<any>([]);
  const [filter, setFilter] = useState<string>("associated");
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    loadVouchersList();
  }, []);

  useEffect(() => {
    applySearchAndFilter();
  }, [data, searchTerm, filter]);

  useEffect(() => {
    if (searchTerm === '') {
        applyFilter(data);
    } else {
        const filteredData = searchData.map((i:any) => {
            const matchedItem = data.find((item:any) => item.IDVoucher === i.IDVoucher);
            if (matchedItem) {
                return {
                    ...i,
                    QuantidadeIDLojista: matchedItem.QuantidadeIDLojista
                };
            }
            return i;
        });
        applyFilter(filteredData);
    }
    }, [searchData]);

  const searchRequest = async () => {
    if (searchTerm == '') {
      setSearchData([]);
      applyFilter(data);
      return;
    }
    const response = await API.searchVoucher(searchTerm);
    if (response.success) setSearchData(response.data);
  };

  const loadVouchersList = async () => {
    const response = await API.getVouchersList();
    setData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    showStoreModal(selectedVoucher !== null);
  }, [selectedVoucher]);

  useEffect(() => {
    showUploadModal(uploadPromise !== null);
  }, [uploadPromise]);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const file: any = event.target.files[0];
    if (file) {
      const promise = API.uploadVouchers(file);
      setUploadPromise(promise);
    }
  };

  const applyFilter = (dataToFilter: any) => {
    if (filter == 'all') {
      setResultsData(dataToFilter);
    } else if (filter == 'associated') {
      setResultsData(dataToFilter.filter((item: any) => item.QuantidadeIDLojista > 0));
    } else {
      setResultsData(dataToFilter.filter((item: any) => item.QuantidadeIDLojista === 0));
    }
  };

  const applySearchAndFilter = () => {
    if (searchTerm == '') {
        applyFilter(data);
    } else {
        searchRequest();
    }
  };

  return (
    <ScreenStyle>
      <PageTitle>Cupons</PageTitle>
      <Modal
        id="modal_message"
        tabIndex={-1}
        dialogClassName="modal-dialog modal-dialog-centered mw-1200px"
        show={storeModal}
        onHide={() => setSelectedVoucher(null)}
        backdrop="static"
      >
        <StoreListModal voucher={selectedVoucher} onClose={() => setSelectedVoucher(null)} />
      </Modal>
      <Modal
        id="upload_modal"
        tabIndex={-1}
        dialogClassName="modal-dialog modal-dialog-centered mw-1000px"
        show={uploadModal}
        onHide={() => setUploadPromise(null)}
        backdrop="static"
      >
        <UploadModal
          promise={uploadPromise}
          onClose={(update: boolean = false) => {
            setUploadPromise(null);
            if (update) loadVouchersList();
          }}
        />
      </Modal>
      <div className="card card-custom gutter-b p-10">
        <h3 className="card-title align-items-start flex-column">
          {!isLoading ? (
            <span className="card-label fw-bold fs-3 mb-1">
              Cupons cadastrados: {data?.length}
            </span>
          ) : null}
        </h3>
        <div className="mb-4"></div>
        { permissions?.some(feature => feature.name.includes('cashback-cupons-add')) ? 
        <div className="d-flex py-1 justify-content-end">
          <div>
            <a href="#" className="btn btn-primary btn-sm btn-light ms-2" onClick={handleUploadClick}>
              Cadastrar Cupons
            </a>
            <a href='https://sadataliteracyexternal.blob.core.windows.net/insight-experience/static/2563ea45-bf93-42f2-b937-09a3ba664671.xlsx' className='btn btn-secondary btn-sm btn-light ms-2'>
                Baixar modelo
            </a>
            <input
              ref={fileInputRef}
              type="file"
              accept=".xlsx"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div> : null }

        <div className="d-flex monitor-alert-box">
            <div className="form-item w-100">
                <label htmlFor="" className="form-label w-150px">Sistema de Busca</label>
                <div className="d-flex w-100">
                    <div className="w-100 d-flex">
                        <input
                        placeholder="Digite aqui o ID do Cupom ou Descrição"
                        type="text"
                        defaultValue={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-input form-control"
                        />
                        <span className="search-icon svg-icon svg-icon-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                    </div>
                    <div className="filter-select  w-250px">
                    <select className="form-control voucher-filter w-250px" value={filter} onChange={(e) => {
                        setFilter(e.target.value);
                        applySearchAndFilter();
                    }}>
                        <option value="associated">Cupons Associados</option>
                        <option value="unassociated">Cupons sem Associação</option>
                        <option value="all">Todos os Cupons</option>
                    </select>
                </div>
                </div>
                
            </div>
        </div>

        
        </div>
      <div className={`card mt-4`}>
        <div className="card-body py-3">
          <div className="table-responsive">
            {!isLoading ? (
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-50px text-center">ID Cupom</th>
                    <th className="min-w-200px text-center">Descrição</th>
                    <th className="min-w-100px text-center">Data Início</th>
                    <th className="min-w-100px text-center">Data Término</th>
                    <th className="min-w-100px text-center">Lojas Participantes</th>
                    <th className="min-w-100px text-center">Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  {resultsData.map((item: any, index: any) => {
                    return (
                      <tr
                        key={`${item.IDVoucher}_${item.DescricaoCupom}_${index}`}
                        className={item.is_actived ? "" : "inactive-item"}
                      >
                        <td className="text-center">
                          <span className="text-dark fw-semibold fs-6">{item.IDVoucher}</span>
                        </td>
                        <td className="text-center">
                          <span className="text-dark fw-semibold d-block fs-6">
                            {item.DescricaoCupom}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-dark fw-semibold d-block fs-6">
                            {formatDate(item.DataInicioVigenciaCupom)}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-dark fw-semibold d-block fs-6">
                            {formatDate(item.DataFimVigenciaCupom)}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-dark fw-semibold d-block fs-6">
                            {item.QuantidadeIDLojista}
                          </span>
                        </td>
                        <td className="text-center">
                          <div className="flex-shrink-0">
                            {item.QuantidadeIDLojista > 0 ? (
                              <a
                                href="#"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 action-btn"
                                onClick={() => setSelectedVoucher(item)}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/menu/store.svg"
                                  className="svg-icon-3"
                                />
                              </a>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="justify-content-center text-center mb-4">
                <p className="mb-5 mt-5">Carregando, aguarde um momento</p>
                <div className="preloader"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ScreenStyle>
  );
};

export default VoucherScreen;