type ActionButtonProps = {
    loading: boolean;
    disabled: boolean;
    isEdit: boolean;
    onClick: () => void;
  };
  
  export const ActionButton = ({ loading, disabled, isEdit, onClick }: ActionButtonProps) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-semibold py-4 px-6 rounded-xl transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg hover:shadow-xl disabled:hover:shadow-lg transform hover:-translate-y-0.5 active:translate-y-0"
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
          <span>Processando...</span>
        </div>
      ) : (
        <span>{isEdit ? 'Atualizar Grupo' : 'Criar Grupo de Permissões'}</span>
      )}
    </button>
  );