import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';

import GridLayout from "react-grid-layout";
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from '@emotion/styled';

import IChart from './../dashboard/IChart';
import Chart from './../dashboard/Chart';

import IPowerBI from './../dashboard/IPowerBI';
import PowerBI from './../dashboard/PowerBI';
import BtnClose from '../../components/BtnClose';

import Loading from '../../components/Loading';
import * as API from '../../api';
import { isSuperAdmin, getUserData, GetPermissions } from '../../../app/modules/auth';

interface Props {
    
}

const StyledGridLayout = styled(GridLayout)`
    background-color: white;

    .closebutton {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const HomePage:FC<Props> = (props:Props) => {
    const permissions = GetPermissions();
    const navigate = useNavigate();

    useEffect(() => {
        if (!permissions.length) return;
        for (let a = 0; a < permissions.length; a++) {
            if (permissions[a].route) {
                navigate(`/${permissions[a].route}`);
                return;
            }
        }
    }, [permissions]);

    return (
        <></>
    );
}

export default HomePage;
